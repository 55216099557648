@import "_var.scss";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

$color-b1: #7e99e6; /*JP,EN*/
$color-b2: #205edb; /*リンク・見出し・ライン・フッター*/
$color-b3: #92c5f5; /*{}*/
$color-b4: #eef7ff; /*薄い青*/
$color-b5: #4d7ee2; /*フッターライン*/
$color-b6: #1f49d3; /*一番濃い青*/

$color: #555555; /*テキスト基本*/
$color-g2: #999999; /*補足,メンバー課程*/
$color-g3: #bbbbbb; /*プレイスホルダ*/
$color-g4: #f7f7f7; /*グレー背景*/
$color-g5: #e0e0e0; /*枠線*/

$cormorant: 'Cormorant', serif;
$noto: 'Noto Sans JP', "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
$shippori: 'Shippori Mincho', "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3","Hiragino Mincho Pro", "ＭＳ 明朝", serif;

$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;

$mhover: .6;

/*##### 404 #####*/
#Page404{
  #MainImg {
    #MainImgInner{
      background-image: url(../img/contents/about/mainimg.jpg);
    }
  }
  .contBox{
    text-align: center;
    p{
      text-align: center;
    }
  }
}

/*##### Top #####*/
  #Page.pageIndex {
    #MainImg {
      margin-bottom: 0;
    }
    #Main {
      #InfomationBox {
        background-color: $color-b2;
        h2 {
          text-align: center;
          color: #fff;
        }
        ul {
          li {
            a {
              padding: 0;
              background:none;
              color: #fff;
              dl {
                dt {
                  text-align: left;
                  span {
                    margin-left: 0;
                    font-weight: $medium;
                  }
                }
                dd {
                  text-align: left;
                  font-weight: $regular;
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .contBox {
        h2 {
          text-align: center;
          color: $color-b2;
        }
      }
      #ContBox01 {
        .txtBox {
          .boxBtn {
            text-align: left;
          }
        }
      }
      #ContBox02 {
        ul.tabList {
          display: flex;
          li {
            padding-left: 15px;
            color: $color-b2;
            cursor: pointer;
            &.is-active {
              background-image: url(../img/icon_09.png);
              background-position: left center;
              background-size: 7px auto;
              background-repeat: no-repeat;
            }
          }
        }
        .tab-content {
          display: none;
          &.is-show {
            display: block;
          }
        }
      }
    }
  }
/*##### About #####*/
  #PageAbout.pageIndex {
    #Main {
      #Research {
        h4 {
          text-align: center;
        }
        h5 {
          text-align: center;
          font-size: 36px;
          color: $color-b2;
          span {
            position: relative;
            padding: 0 60px;
            display: inline-block;
            &::before,
            &::after {
              position: absolute;
              top: 50%;
              display: inline-block;
              content: "";
              width: 44px;
              height: 1px;
              background-color: $color-b2;
            }
            &::before {
              left: 0;
            }
            &::after {
              right: 0;
            }
          }
        }
        .txtBox {
          h4 {
            text-align: left;
            span {
              background:linear-gradient(transparent 65%, $color-b4 65%);
            }
          }
        }
        .contSubBox {
          ul.listBox {
            background-color: $color-g4;
            li {
              position: relative;
              &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                width: 10px;
                height: 2px;
                background-color: $color-b2;
              }
            }
          }
        }
      }
    }
  }
/*##### News #####*/
  #PageNews.pageIndex {
    #Main {
      .contSubBox {
        h3 {
          text-align: center;
          span {
            position: relative;
            padding: 0 60px;
            display: inline-block;
            &::before,
            &::after {
              position: absolute;
              top: 50%;
              display: inline-block;
              content: "";
              width: 44px;
              height: 1px;
              background-color: $color-b2;
            }
            &::before {
              left: 0;
            }
            &::after {
              right: 0;
            }
          }
        }
        p {
          &.noPost {
            text-align: center;
            font-size: 20px;
          }
        }
      }
    }
  }
  #PageNews.pageEntry {
    #Main {
      .entryBoxWall {
        background-color: $color-g4;
        .title {
          text-align: left;
          span {
            display: block;
            font-size: 16px;
            font-family: $noto;
            b {
              margin-left: 20px;
              font-weight: $semibold;
              &::before,
              &::after {
                color: $color-b3;
                font-weight: $medium;
              }
              &::before {
                content: "{ ";
              }
              &::after {
                content: " }";
              }
            }
          }
        }
      }
      .pageNation {
        ul {
          position: relative;
          height: 50px;
          li {
            position: absolute;
            a {
              font-size: 14px;
            }
            &.list {
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              a {
                display: block;
                width: 140px;
                height: 50px;
                border: solid 1px $color-b2;
                line-height: 50px;
                text-align: center;
              }
            }
            &.pre,
            &.next {
              top: 15px;
              a {
                background-size: 26px auto;
                background-repeat: no-repeat;
              }
            }
            &.pre {
              left: 0;
              a {
                padding-left: 34px;
                background-image: url(../img/icon_12.png);
                background-position: left bottom 8px;
              }
            }
            &.next {
              right:0;
              a {
                padding-right: 34px;
                background-image: url(../img/icon_13.png);
                background-position: right bottom 8px;
              }
            }
          }
        }
      }
    }
  }
/*##### Project #####*/
  #PageProject.pageCategory {
    #Main {
      .contBox {
        h3 {
          text-align: center;
          span {
            position: relative;
            padding: 0 68px;
            display: inline-block;
            &::before,
            &::after {
              position: absolute;
              top: 50%;
              display: inline-block;
              content: "";
              width: 45px;
              height: 1px;
              background-color: $color-b2;
            }
            &::before {
              left: 0;
            }
            &::after {
              right: 0;
            }
          }
        }
      }
    }
  }
  #PageProject.pageEntry {
    #Main {
      #FirstBox {
        position: relative;
        text-align: center;
        &::before {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          content: "Project";
          color: $color-b4;
          font-family: $cormorant;
          line-height: 1em;
          z-index: -1;
        }
        p.caption{
          text-align: right;
        }
      }
      .contSubBox {
        &>h4 {
          font-size: 32px;
          b {
            position: relative;
            display: block;
            font-family: $cormorant;
            &::after {
              position: absolute;
              top: 50%;
              left: 0;
              content: "";
              width: 100%;
              height: 1px;
              background-color: $color-b2;
              z-index: -1;
            }
            span {
              display: inline-block;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
/*##### Achievement #####*/
  #PageAchievement.pageIndex {
    #Main {
      .contBox {
        margin-bottom: 55px;
        .contSubBox {
          margin-bottom: 20px;
          background-color: $color-g4;
          &>h4 {
            text-align: center;
            span {
              position: relative;
              display: inline-block;
              &::before,
              &::after {
                position: absolute;
                top: 50%;
                display: inline-block;
                content: "";
                width: 45px;
                height: 1px;
                background-color: $color-b2;
              }
              &::before {
                left: 0;
              }
              &::after {
                right: 0;
              }
            }
          }
          &>ol {
            counter-reset:number01;
            list-style: none;
            li {
              position: relative;
              padding-left: 28px;
              &::before {
                position: absolute;
                top: -2px;
                left: 0;
                counter-increment: number01;
                content: counter(number01) '. ';
                font-size: 20px;
                letter-spacing: -0.04em;
                font-family: $shippori;
                color: $color-b2;
              }
              div {
                b {
                  font-weight: $semibold;
                }
                i {
                  font-style: italic;
                }
              }
            }
          }
        }
      }
    }
  }
/*##### Member #####*/
  #PageMember.pageIndex {
    #Main {
      .contSubBox {
        h4 {
          text-align: center;
          font-size: 28px;
          span {
            position: relative;
            display: inline-block;
            padding: 0 68px;
            &::before,
            &::after {
              position: absolute;
              top: 50%;
              display: inline-block;
              content: "";
              width: 44px;
              height: 1px;
              background-color: $color-b2;
            }
            &::before {
              left: 0;
            }
            &::after {
              right: 0;
            }
          }
        }
        dl {
          font-family: $shippori;
          font-weight: $extrabold;
          dt {
            font-size: 20px;
            span {
              font-size: 16px;
              letter-spacing: 0;
            }
          }
          dd {
            &:first-of-type {
              color: $color-g2;
            }
            &.cmt {
              font-family: $noto;
              font-weight: $medium;
            }
            ul {
              li {
                position: relative;
                margin-left: 16px;
                &::before {
                  position: absolute;
                  top: 12px;
                  left: -16px;
                  content: "";
                  width: 8px;
                  height: 1px;
                  background-color: $color-b2;
                }
              }
            }
          }
        }
      }
    }
  }
/*##### Join #####*/
  #PageJoin.pageIndex {
    #Main {
      .contBox {
        h3 {
          position: relative;
          &::after {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index:-1;
            font-family: $cormorant;
            line-height: 1em;
            color: $color-b4;
          }
        }
        &>.innerBasic {
          p {
            text-align: center;
          }
        }
        .contSubBox {
          h4 {
            width: 100%;
            position: relative;
            &::before {
              position: absolute;
              top: 50%;
              left: 0;
              content: "";
              width: 100%;
              height: 1px;
              background-color: $color-b2;
              z-index: -1;
            }
            span {
              display: inline-block;
              background-color: #fff;
            }
          }
        }
      }
      #Student {
        h3 {
          &::after {
            content: "Student";
          }
        }
      }
      #Pharmacist {
        h3 {
          &::after {
            content: "Pharmacist";
          }
        }
        ul {
          font-family: $shippori;
          li {
            background-color: $color-g4;
            text-align: center;
            font-size: 24px;
            color: $color-b2;
          }
        }
      }
      #PartTime {
        h3 {
          &::after {
            content: "Part-time";
            white-space: nowrap;
          }
        }
      }
    }
  }
/*##### Contact #####*/
  #PageContact {
    #Main {
      p {
        text-align: center;
      }
      #ContactForm {
        .contSubBox {
          background-color: $color-g4;
        }
      }
    }
  }
  #PageContact.pageConfirm {
    #Main {
      #ContactForm {
        #ErrorBox {
          margin-bottom: 50px;
          p {
            margin-bottom: 10px;
          }
        }
      }
    }

  }
  #PageContact.pageThanks {
    #Main {
      #ContactForm {
        h4 {
          text-align: center;
          margin-bottom: 30px;
        }
      }
    }
  }
/*##### About #####*/
  #PageEn.pageIndex {
    #Main {
      #Research {
        h4 {
          text-align: center;
        }
        .txtBox {
          h4 {
            text-align: left;
            span {
              background:linear-gradient(transparent 65%, $color-b4 65%);
            }
          }
        }
      }
    }
  }

/*##### MainImg #####*/
  #PageAbout {
    #MainImg {
      #MainImgInner {
        background-image: url(../img/contents/about/mainimg.jpg);
      }
    }
  }
  #PageNews {
    #MainImg {
      #MainImgInner {
        background-image: url(../img/contents/news/mainimg.jpg);
      }
    }
  }
  #PageProject {
    #MainImg {
      #MainImgInner {
        background-image: url(../img/contents/project/mainimg.jpg);
      }
    }
  }
  #PageAchievement {
    #MainImg {
      #MainImgInner {
        background-image: url(../img/contents/achievement/mainimg.jpg);
      }
    }
  }
  #PageMember {
    #MainImg {
      #MainImgInner {
        background-image: url(../img/contents/member/mainimg.jpg);
      }
    }
  }
  #PageJoin {
    #MainImg {
      #MainImgInner {
        background-image: url(../img/contents/join/mainimg.jpg);
      }
    }
  }
  #PageContact {
    #MainImg {
      #MainImgInner {
        background-image: url(../img/contents/contact/mainimg.jpg);
      }
    }
  }

@media print,
screen and (min-width: 768px) {
/*##### Top #####*/
  #Page.pageIndex {
    #MainImg {
      padding-top: 0;
      #MainImgInner {
        height: 780px;
        position: relative;
        width: 100%;
        padding-top: 125px;
        overflow: hidden;
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    #InfomationBox {
      padding: 15px 0 25px 0;
      .innerBasic {
        width: 100%;
        max-width: 1040px;
      }
      h2 {
        font-size: 36px;
        margin-bottom: 10px;
      }
      ul {
        margin-bottom: 0;
        li {
          a {
            dl {
              dt {
                width: 175px;
              }
              dd {
                width: calc(100% - 175px);
              }
            }
          }
        }
      }
    }
    #Main {
      .contBox {
        h2 {
          font-size: 70px;
          line-height: 1em;
        }
      }
      #ContBox01 {
        margin: 0 auto;
        margin-top: 120px;
        margin-bottom: 110px;
        width: 100%;
        max-width: 1440px;
        //min-width: 1200px;
        background-image: url(../img/contents/top/img_01-pc.jpg);
        background-position: left 5px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        .txtBox {
          //margin-left: 580px;
          margin-left: 48.3%;
          width: 51.7%;
          max-width: 620px;
          h2 {
            margin-top: -5px;
            margin-bottom: 20px;
            text-align: left;
          }
          h3 {
            margin-bottom: 20px;
            text-align: left;
            line-height: 1.4;
            font-size: 36px;
          }
          p {
            margin-bottom: 20px;
          }
          .boxBtn {
            margin-top: 40px;
          }
        }
      }
      #ContBox02 {
        margin-bottom: 100px;
        .innerBasic {
          width: 100%;
          max-width: 1000px;
        }
        h2 {
          margin-bottom: 30px;
        }
        ul.tabList {
          justify-content:center;
          margin-bottom: 35px;
          li {
            margin-right: 45px;
            &:last-of-type {
              margin-right: 0;
            }
            &:hover {
              opacity: $mhover;
            }
          }
        }
      }
      .wallBox {
        width: 100%;
        height: 320px;
        background-image: url(../img/contents/top/bg.jpg);
        background-size: cover;
        background-position: center;
      }
      #ContBox03 {
        margin-top: 90px;
        h2 {
          margin-bottom: 50px;
        }
        ul {
          margin-bottom: 5px;
        }
        .contSubBox {
          margin-top: 90px;
          text-align: center;
          img {
            border: solid 1px $color-g5;
            width: 370px;
            height: auto;
          }
        }
      }
      #Access {
        margin-top: 120px;
        margin-bottom: 160px;
        .add {
          h2 {
            margin-bottom: 30px;
            text-align: left;
          }
        }
      }
    }
  }
/*##### About #####*/
  #PageAbout.pageIndex {
    .linkBox {
      margin-bottom: 100px;
    }
    #Main {
      margin-bottom: 230px;
      h4 {
      }
      #About {
        margin-bottom: 100px;
        h3 {
          margin-bottom: 15px;
        }
        h4 {
          text-align: center;
        }
        figure {
          margin: 45px 0 55px;
          height: 386px;
          width: 100%;
          background-image: url(../img/contents/about/img_01-pc.jpg);
          background-position: center top;
          background-repeat: no-repeat;
          img {
            display: none;
          }
        }
        .contSubBox {
          width: 100%;
          max-width: 800px;
          margin: 0 auto;
          margin-bottom: 85px;
          p {
            margin-bottom: 25px;
          }
        }
      }
      #Research {
        h3 {
          margin-bottom: 40px;
        }
        .contSubBox {
          margin-bottom: 110px;
          &:nth-of-type(1),
          &:nth-of-type(2) {
            margin: 0 auto;
            width: 100%;
            max-width: 1440px;
            //min-width: 1200px;
            background-repeat: no-repeat;
            background-size: 100% auto;
          }
          &:nth-of-type(1) {
            margin-bottom: 85px;
            background-image: url(../img/contents/about/img_02-pc.jpg);
            background-position: right top;
          }
          &:nth-of-type(2) {
            margin-bottom: 80px;
            background-image: url(../img/contents/about/img_03-pc.jpg);
            background-position: left top;
            .txtBox {
//              margin-left: 580px;
              margin-left: 48.3%;
              padding-bottom: 46px;
            }
          }
          &:nth-of-type(4) {
            .innerBasic {
              max-width: 1200px;
              padding: 0;
            }
          }
          h4 {
            line-height: 1.4;
          }
          h5 {
            margin-bottom: 45px;
          }
          .txtBox {
            width: 51.7%;
            max-width: 620px;
            h4 {
              margin-bottom: 35px;
            }
          }
          ul.listBox {
            display: flex;
            flex-wrap:wrap;
            margin-top: 45px;
            padding-top: 65px;
            padding-bottom: 60px;
            padding-right: 6.6%;
            padding-left: 6.6%;
//            padding: 65px 80px 60px 80px;
            li {
              margin-bottom: 14px;
              width: calc(50% - 50px);
//              width: 470px;
              line-height: 1.75;
              margin-left: 30px;
              &::before {
                top: 15px;
                left: -30px;
              }
              &:nth-child(odd) {
                margin-right: 40px;
              }
            }
          }
        }
      }
    }
  }
/*##### News #####*/
  #PageNews {
    #Main {
      .linkBox {
        margin-bottom: 100px;
        ul:first-of-type {
          li {
            a {
              width: 200px;
            }
          }
        }
      }
      .contBox {
        .innerBasic {
          max-width: 1200px;
          padding: 0;
        }
      }
    }
  }
  #PageNews.pageIndex {
    #Main {
      margin-bottom: 180px;
      .contSubBox {
        h3 {
          margin-bottom: 30px;
          font-size: 36px;
        }
        &.box01 {
          margin-bottom: 90px;
        }
      }
    }
  }
  #PageNews.pageCategory,
  #PageNews.pageArchive {
    #Main {
      margin-bottom: 150px;
      h3 {
        font-size: 60px;
        line-height: 1.25em;
        margin-bottom: 45px;
      }
    }
  }
  #PageNews.pageEntry {
    #Main {
      margin-bottom: 180px;
      .entryBoxWall {
        margin-bottom: 30px;
        padding: 95px 100px 90px 100px;
        .title {
          margin-bottom: 45px;
          font-size: 32px;
          span {
            margin-bottom: 15px;
          }
        }
      }
      .pageNation {
        ul {
          li.list {
            a {
              &:hover {
                text-decoration: none;
                opacity: $mhover;
              }
            }
          }
        }
      }
    }
  }
/*##### Project #####*/
  #PageProject.pageIndex {
    #Main {
      margin-top: 160px;
      margin-bottom: 224px;
      ul.projectList {
        margin-bottom: 75px;
      }
      .box02 {
        margin-top: 90px;
        text-align: center;
        img {
          border: solid 1px $color-g5;
          width: 370px;
          height: auto;
        }
      }
    }
  }
  #PageProject.pageCategory {
    #TopicPath {
      margin-bottom: 65px;
    }
    #Main {
      margin-bottom: 213px;
      .contBox {
        h3 {
          margin-bottom: 50px;
          font-size: 36px;
        }
        ul {
          margin-bottom: 75px;
        }
      }
    }
  }
  #PageProject.pageEntry {
    #Main {
      margin-top: 95px;
      margin-bottom: 150px;
      .contBox:nth-of-type(1) {
        margin-bottom: 100px;
        .innerBasic {
          width: 100%;
          max-width: 1040px;
          padding-right: 20px;
          padding-left: 20px;
        }
      }
      #FirstBox {
        padding-top: 135px;
        &::before {
          font-size: 160px;
        }
        h3 {
          margin-bottom: 50px;
          line-height: 1.25;
        }
        img {
          margin-bottom: 10px;
          width: 100%;
          max-width: 800px;
        }
        p.caption {
          font-size: 12px;
        }
      }
      .contSubBox {
        margin-bottom: 70px;
        &>h4 {
          margin-bottom: 25px;
          b {
            margin-bottom: 0px;
            font-size: 28px;
            span {
             padding-right: 20px;
            }
          }
        }
      }
      #ImageBox {
        margin-top: 110px;
        margin-bottom: 80px;
        img {
          margin-bottom: 40px;
          max-width: 100%;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .linkBox {
        margin-top: 115px;
      }
    }
  }
/*##### Achievement #####*/
  #PageAchievement.pageIndex {
    #Main {
      .innerBasic {
        padding: 0;
        max-width: 1200px;
      }
      .linkBox {
        margin-bottom: 85px;
        ul {
          li {
            a {
              width: 160px;
            }
          }
        }
      }
      .contBox {
        margin-bottom: 100px;
        &>h3 {
          margin-bottom: 45px;
          font-size: 54px;
        }
        .contSubBox {
          margin-bottom: 30px;
          padding-top: 90px;
          padding-bottom: 75px;
          padding-right: 8.3%;
          padding-left: 8.3%;
          //padding: 90px 100px 75px 100px;
          &>h4 {
            margin-bottom: 35px;
            font-size: 34px;
            span {
              padding: 0 70px;
            }
          }
          &>ol {
            li {
              margin-bottom: 22px;
              p {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
/*##### Member #####*/
  #PageMember.pageIndex {
    #TopicPath {
      margin-bottom: 100px;
    }
    #Main {
      .innerBasic {
        padding: 0;
        max-width: 1200px;
      }
      .contSubBox {
        margin-bottom: 100px;
        padding: 0 20px;
        h3 {
          margin-bottom: 45px;
          font-size: 56px;
        }
        h4 {
          margin-top: -5px;
          margin-bottom: 75px;
        }
        .memberList {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 60px;
          dl {
            position: relative;
            //float: left;
            margin-bottom: 60px;
            padding-top: 18px;
            padding-left: 220px;
            //width: 580px;
            width: calc( 50% - 20px);
            min-height: 200px;
            &:nth-child(odd) {
              margin-right: 40px;
            }
            dt {
              line-height: 1.4;
            }
            dd {
              ul {
                margin-top: 3px;
                li {
                  line-height: 1.4;
                }
              }
              &.cmt {
                margin-top: 14px;
                line-height: 1.75;
              }
              &.photo {
                position: absolute;
                top: 0;
                left: 0;
                img {
                  width: 200px;
                  height: auto;
                }
              }
            }
          }
          &.column4 {
            dl {
              padding: 0;
              //width: 270px;
              width: calc((100% - 120px) / 4);
              margin-right: 40px;
              min-height: auto;
              dt {
                span {
                  display: block;
                }
              }
              &:nth-child(4n) {
                margin-right: 0;
              }
            }
          }
          &.noPhoto {
            dl {
              padding: 0;
              min-height: auto;
              dt {
                span {
                  display: block;
                }
              }
            }
          }
        &::after {
            content: "";
            display: block;
            clear: both;
          }
        }
      }
      .subBox02 {
        .memberList {
          dl {
            &.type01 {
              padding-top: 40px;
            }
          }
        }
      }
      .subBox03 {
        .memberList {
          &:nth-of-type(1) {
            margin-bottom: 0;
            dl {
              padding-top: 10px;
            }
          }
        }
        h4 {
          &:nth-of-type(2) {
            margin-top: -8px;
            margin-bottom: 50px;
          }
        }
      }
      .subBox05 {
        .memberList.noPhoto {
          dl {
            dt {
              span {
                display: inline;
              }
            }
          }
        }
      }
    }
  }
/*##### Join #####*/
  #PageJoin.pageIndex {
    .linkBox {
      margin-bottom: 55px;
      ul {
        li {
          &:nth-of-type(1) {
            a {
              width: 200px;
            }
          }
          &:nth-of-type(2) {
            a {
              padding-left: 60px;
              padding-right:60px;
            }
          }
        }
      }
    }
    #Main {
      margin-bottom: 195px;
      .contBox {
        margin-bottom: 60px;
        h3 {
          padding-top: 108px;
          margin-bottom: 45px;
          font-size: 56px;
          line-height: 1.3;
          &::after {
            font-size: 160px;
            letter-spacing: 0.02em;
          }
        }
        .contSubBox {
          .innerBasic {
            width: 100%;
            max-width: 1040px;
          }
          h4 {
            margin-bottom: 25px;
            span {
              padding-right: 25px;
            }
          }
        }
      }
      #Student {
        .contSubBox {
          margin-bottom: 90px;
          &:nth-of-type(1) {
            margin-top: 70px;
          }
          &:nth-of-type(2),
          &:nth-of-type(3) {
            position: relative;
            width: 100%;
            max-width: 1440px;
            //min-width: 1200px;
            margin: 0 auto;
            margin-bottom: 80px;
            background-size: 100% auto;
            background-repeat: no-repeat;
            z-index: -1;
            .txtBox {
              padding-top: 30px;
              width: 52%;
              max-width: 520px;
              min-height: 430px;
            }
          }
          &:nth-of-type(2) {
            background-image: url(../img/contents/join/img_01-pc.jpg);
            background-position: left top;
            .txtBox {
              margin-left: 48%;
//              margin-left: 480px;
              padding-bottom: 1px;
            }
          }
          &:nth-of-type(3) {
            background-image: url(../img/contents/join/img_02-pc.jpg);
            background-position: right top;
            h4 {
              span {
                padding-right: 15px;
              }
            }
            .boxBtn {
              margin-top: 47px;
              padding-bottom: 18px;
              text-align: left;
            }
          }
          &:last-of-type {
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
            padding-bottom: 95px;
          }
        }
      }
      #Pharmacist {
        .innerBasic {
          padding-bottom: 95px;
        }
        ul {
          display: table;
          border-collapse: separate;
          margin: 55px 0;
          li {
            width: 305px;
            height: 285px;
            padding: 0 10px;
            display: table-cell;
            vertical-align: middle;
            border-right: solid #fff 20px;
            &:last-of-type {
              width: 285px;
              border-right: none;
            }
          }
        }
        p {
          width: 100%;
          max-width: 1000px;
          margin: 0 auto;
          margin-bottom: 25px;
        }
      }
      #PartTime {
        .innerBasic {
          width: 100%;
          max-width: 1000px;
        }
      }
    }
  }
/*##### Contact #####*/
  #PageContact {
    #Main {
      margin-bottom: 130px;
      .innerBasic {
        padding: 0;
        max-width: 1200px;
      }
      .linkBox {
        margin-bottom: 85px;
        ul {
          li {
            &:first-of-type {
              width: 200px;
            }
          }
        }
      }
      #ContactForm {
        margin-bottom: 85px;
        h3 {
          margin-bottom: 15px;
        }
        .contSubBox {
          margin-top: 45px;
          padding: 90px 0;
        }
      }
      #Access {
        .innerBasic {
          max-width: 1240px;
          padding: 0 20px;
        }
        h3 {
          margin-bottom: 25px;
        }
      }
    }
  }
  #PageContact.pageIndex {
    #Main {
      #ContactForm {
        table {
          tr {
            &:nth-of-type(4) {
              th {
                padding-top: 0;
              }
              td {
                label:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  #PageContact.pageConfirm {
    #Main {
      #ContactForm {
        table {
          tr{
            th {
              padding-top: 0;
            }
          }
        }
        .boxBtn {
          margin-bottom: 25px;
        }
        input.reset {
          color: $color-b2;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
/*##### English #####*/
  #PageEn.pageIndex {
    #GlobalNav {
      ul.language {
        li {
          a {
            color: $color-b2;
          }
          &:first-of-type {
            a {
              color: $color-b1;
            }
          }
        }
      }
    }
    #Footer {
      #TextLink {
        ul.language {
          li {
            a {
              color: #fff;
            }
            &:first-of-type {
              a {
                color: $color-b1;
              }
            }
          }
        }
      }
      #UniversityInfo {
        dl {
          width: auto;
          margin-right: 60px;
          dd {
            line-height: 1.7;
          }
        }
      }
    }
    #MainImg {
      padding-top: 0;
      #MainImgInner {
        width: 100%;
        height: 780px;
        padding-top: 125px;
        overflow: hidden;
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    #Main {
      margin-bottom: 115px;
      h4 {
      }
      p {
        margin-bottom: 30px;
        text-align: left;
      }
      #About {
        margin-bottom: 100px;
        h3 {
          margin-bottom: 20px;
        }
        h4 {
          text-align: center;
          line-height: 1.4;
        }
        figure {
          margin: 45px 0 55px;
          width: 100%;
          height: 386px;
          background-repeat: no-repeat;
          background-position: center top;
          background-image: url(../img/contents/about/img_01-pc.jpg);
          img {
            display: none;
          }
        }
        .contSubBox {
          width: 100%;
          max-width: 800px;
          margin: 0 auto;
          margin-bottom: 80px;
        }
      }
      #Research {
        h3 {
          margin-bottom: 40px;
        }
        .contSubBox {
          &:nth-of-type(1),
          &:nth-of-type(2) {
            margin: 0 auto;
            width: 100%;
            max-width: 1440px;
            //min-width: 1200px;
            background-repeat: no-repeat;
            background-size: 100% auto;
          }
          &:nth-of-type(1) {
            margin-bottom: 85px;
            background-image: url(../img/contents/about/img_02-pc.jpg);
            background-position: right top;
          }
          &:nth-of-type(2) {
            background-image: url(../img/contents/about/img_03-pc.jpg);
            background-position: left top;
            .txtBox {
              margin-left: 48.3%;
              padding-bottom: 46px;
            }
          }
          h4 {
            line-height: 1.3;
            font-size: 40px;
          }
          .txtBox {
            width: 51.7%;
            max-width: 620px;
            h4 {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 840px) {
  #PageEn.pageIndex #Footer #UniversityInfo dl {
    margin-right: 30px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1039px) {
  #PageMember.pageIndex #Main .contSubBox .memberList dl {
    width: 100%;
  }

}


@media print,
screen and (max-width: 767px) {
/*##### Top #####*/
  #Page.pageIndex {
    #MainImg {
      #MainImgInner {
        height: 600px;
        background-image: url(../img/contents/top/mainimg_sp.jpg);
      }
    }
    #Main {
      margin-bottom: 105px;
      h2 {
          font-size: 56px;
      }
      #InfomationBox {
        padding: 30px 0 25px 0;
        h2 {
          margin-bottom: 28px;
          font-size: 28px;
        }
        ul {
          margin-bottom: 0;
          li {
            dl {
              dt {
                margin-bottom: 8px;
              }
              dd {
                line-height: 1.65;
              }
            }
          }
        }
      }
      #ContBox01 {
        margin-top: 30px;
        margin-bottom: 45px;
        h3 {
          margin-bottom: 15px;
          font-size: 28px;
          letter-spacing: -0.02em;
          line-height: 1.45;
        }
        .txtBox {
          margin-bottom: 50px;
          .boxBtn {
            margin-top: 32px;
          }
        }
        figure {
          img {
            width: 100%;
          }
        }
      }
      #ContBox02 {
        margin-bottom: 75px;
        .innerBasic {
          margin-left: 0;
          margin-right: 0;
          .area {
            margin-left: 20px;
            margin-right: 20px;
          }
        }
        h2 {
          margin-bottom: 15px;
        }
        .tab-content {
          margin-left: 20px;
          margin-right: 20px;
        }
        ul.tabList {
          margin-bottom: 15px;
          padding-bottom: 5px;
          width: 100%;
          overflow-x: scroll;
          li {
            margin-right: 15px;
            flex-shrink: 0;
            &:first-of-type {
              margin-left: 20px;
            }
          }
        }
      }
      .wallBox {
        width: 100%;
        height: 160px;
        //background-image: url(../img/contents/top/bg_sp.jpg);
        background-size: cover;
        background-position: center;
      }
      #ContBox03 {
        margin-top: 45px;
        h2 {
          margin-bottom: 20px;
        }
        .contSubBox {
          margin-top: 70px;
          text-align: center;
          img {
            border: solid 1px $color-g5;
            width: 335px;
            height: auto;
          }
        }
      }
      #Access {
        margin-top: 45px;
        h2 {
          text-align: left;
        }
      }
    }
  }
/*##### About #####*/
  #PageAbout.pageIndex {
    .linkBox {
      margin-bottom: 30px;
    }
    #Main {
      margin-bottom: 115px;
      h4 {
        line-height: 1.5;
      }
      figure {
        img {
          width: 100%;
        }
      }
      #About {
        margin-bottom: 50px;
        .innerBasic {
          margin-left: 0;
          margin-right: 0;
        }
        h3 {
          margin-bottom: 10px;
        }
        h4 {
          text-align: center;
        }
        .contSubBox {
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 55px;
        }
        figure {
          margin: 33px 0;
        }
      }
      #Research {
        h4 {
          margin-bottom: 27px;
          line-height: 1.4;
        }
        .contSubBox {
          ul.listBox {
            padding: 35px 20px 20px;
            li {
              margin-left: 20px;
              margin-bottom: 14px;
              line-height: 1.7;
              &::before {
                top: 13px;
                left: -20px;
              }
            }
          }
          &:nth-of-type(1) {
            margin-bottom: 50px;
            figure {
              margin: 33px 0;
            }
          }
          &:nth-of-type(2) {
            margin-bottom: 30px;
            figure {
              margin: 33px 0;
            }
          }
          &:nth-of-type(3) {
            margin-bottom: 70px;
            h5 {
              margin-bottom: 25px;
            }
            ul {
              margin-bottom: 25px;
            }
          }
          &:nth-of-type(4) {
            .innerBasic {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
/*##### News #####*/
  #PageNews {
    #Main {
      .linkBox {
        margin-bottom: 45px;
      }
      .contBox {
        .innerBasic {
          margin: 0;
        }
      }
    }
  }
  #PageNews.pageIndex {
    #Main {
      margin-bottom: 130px;
      .contSubBox {
        h3 {
          margin-bottom: 35px;
          font-size: 28px;
        }
        &.box01 {
          margin-bottom: 70px;
        }
        &.box02 {
          margin-bottom: 50px;
        }
      }
    }
  }
  #PageNews.pageCategory,
  #PageNews.pageArchive {
    #Main {
      margin-bottom: 120px;
      h3 {
        font-size: 42px;
        line-height: 1.25em;
        margin-bottom: 30px;
      }
    }
  }
  #PageNews.pageEntry {
    #Main {
      margin-bottom: 115px;
      .entryBoxWall {
        margin-bottom: 45px;
        padding: 58px 20px 35px 20px;
        .title {
          margin-bottom: 33px;
          font-size: 26px;
          line-height: 1.35;
          span {
            margin-bottom: 20px;
          }
        }
        .entryBox {
          p {
            line-height: 1.7;
          }
        }
      }
      .pageNation {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
/*##### Project #####*/
  #PageProject.pageIndex {
    #Main {
      margin-top: 75px;
      margin-bottom: 127px;
      ul.projectList {
        margin-bottom: 36px;
      }
      .box02 {
        margin-top: 70px;
        text-align: center;
        img {
          border: solid 1px $color-g5;
          width: 335px;
          height: auto;
        }
      }
    }
  }
  #PageProject.pageCategory {
    #Main {
      margin-bottom: 113px;
      .contBox {
        h3 {
          margin-bottom: 25px;
          font-size: 36px;
        }
        ul {
          margin-bottom: 5px;
        }
      }
    }
  }
  #PageProject.pageEntry {
    #Main {
      margin-top: 85px;
      margin-bottom: 150px;
      .contBox:nth-of-type(1) {
        margin-bottom: 40px;

      }
      #FirstBox {
        padding-top: 60px;
        &::before {
          font-size: 80px;
        }
        h3 {
          margin-bottom: 35px;
          line-height: 1.25;
          font-size: 36px;
        }
        img {
          margin-bottom: 15px;
          width: 100%;
        }
        p.caption {
          font-size: 12px;
        }
      }
      .contSubBox {
        margin-bottom: 55px;
        &>h4 {
          font-size: 26px;
          margin-bottom: 15px;
          letter-spacing: 0.02em;
          b {
            margin-bottom: 0px;
            font-size: 24px;
            span {
             padding-right: 20px;
            }
          }
        }
      }
      #ImageBox {
        margin-top: 65px;
        img {
          margin-bottom: 20px;
          width: 100%;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .linkBox {
        margin: 68px 0 0 0;
        ul {
          justify-content:center;
          li {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
/*##### Achievement #####*/
  #PageAchievement.pageIndex {
    #Main {
      .innerBasic {
        margin-left: 0;
        margin-right: 0;
      }
      .linkBox {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 50px;
      }
      .contBox {
        &>h3 {
          margin-bottom: 30px;
          font-size: 42px;
        }
        .contSubBox {
          padding: 50px 20px;
          &>h4 {
            margin-bottom: 30px;
            font-size: 26px;
            span {
              &::before,
              &::after {
                content: none;
              }
            }
          }
          &>ol {
            li {
              margin-bottom: 20px;
              div {
                p {
                  line-height: 1.75;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
/*##### Member #####*/
  #PageMember.pageIndex {
    #Main {
      margin-bottom: 135px;
      .innerBasic {
        margin-left: 0;
        margin-right: 0;
      }
      .contSubBox {
        margin-bottom: 55px;
        padding-bottom: 10px;
        h3 {
          font-size: 44px;
          margin-bottom: 40px;
        }
        .memberList {
          margin-left: 20px;
          margin-right: 20px;
          dl {
            position: relative;
            margin-bottom: 45px;
            dt {
              margin-left: 138px;
              margin-bottom: 3px;
              line-height: 1.3;
              span {
                display: block;
                letter-spacing: 0.01em;
              }
            }
            dd {
              margin-left: 138px;
              ul {
                margin-top: 3px;
                li {
                  line-height: 1.25;
                }
              }
              &:first-of-type {
                letter-spacing: 0.01em;
              }
              &.cmt {
                margin-top: 10px;
                margin-left: 0;
                line-height: 1.7;
              }
              &.photo {
                position: absolute;
                top: 0;
                left: 0;
                margin-left: 0;
                img {
                  width: 120px;
                  height: 120px;
                }
              }
            }
          }
          &.column4 {
            margin-bottom: 50px;
            dl {
              margin-bottom: 20px;
              padding: 0;
              dt,dd {
                margin-left: 0;
              }
              dt {
                span {
                  display: inline;
                }
              }
            }
          }
          &.noPhoto {
            dl {
              margin-bottom: 20px;
              dt,dd {
                margin-left: 0;
              }
            }
          }
        }
      }
      .subBox02 {
        .memberList {
          dl {
            padding-top: 25px;
            &.type01 {
              dd.cmt {
                margin-top: 30px;
              }
            }
          }
        }
      }
      .subBox03 {
        h3 {
          margin-bottom: 30px;
        }
        h4 {
          margin-bottom: 30px;
        }
        .memberList {
          dl {
            padding-top: 25px;
            &.type01 {
              dd.cmt {
                margin-top: 30px;
              }
            }
          }
        }
      }
      .subBox05 {
        .memberList.noPhoto {
          dl {
            margin-bottom: 26px;
            dt {
              span {
                &:first-of-type {
                  margin-bottom: 6px;
                  margin-left: -0.6em;
                }
              }
            }
          }
        }
      }
    }
  }
/*##### Join #####*/
  #PageJoin.pageIndex {
    .linkBox {
      margin-bottom: 60px;
      ul {
        display: block;
        li {
          width: 100%;
          margin-right: 0;
          margin-left: 0;
          a {
            width: 100%;
          }
          &:nth-of-type(2) {
            a {
              padding-top: 8px;
              height: 60px;
              border-radius: 30px;
              line-height: 1.3;
            }
          }
        }
      }
    }
    #Main {
      margin-bottom: 125px;
      .contBox {
        margin-bottom: 45px;
        h3 {
          margin-bottom: 35px;
          padding-top: 51px;
          font-size: 36px;
          line-height: 1.25;
          &::after {
            font-size: 80px;
          }
        }
      }
      #Student {
        &>.innerBasic {
          margin-bottom: 50px;
        }
        .contSubBox {
          margin-bottom: 60px;
          figure {
            margin-bottom: 30px;
            img {
              width: 100%;
            }
          }
          h4 {
            margin-bottom: 30px;
            span {
              padding-right: 12px;
              white-space: nowrap
            }
          }
          .boxBtn {
            margin-top: 40px;
            text-align: left;
            a, span {
              padding-left: 32px;
              &::after {
                top: 16px;
                left: 85px;
              }
            }
          }
          &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 30px;
          }
        }
      }
      #Pharmacist {
        .innerBasic {
          margin-left: 0;
          margin-right: 0;
          padding-left: 20px;
          padding-right: 20px;
        }
        ul {
          margin: 35px 0;
          li {
            margin-bottom: 15px;
            padding: 20px 15px 23px;
            line-height: 1.7;
          }
        }
      }
    }
  }
/*##### Contact #####*/
  #PageContact {
    #Main {
      margin-bottom: 100px;
      .linkBox {
        margin-bottom: 30px;
      }
      #ContactForm {
        .innerBasic {
          margin-left: 0;
          margin-right: 0;
        }
        h3 {
          margin-bottom: 20px;
        }
        p {
          margin-left: 20px;
          margin-right: 20px;
        }
        .contSubBox {
          margin:45px 0;
          padding: 50px 20px 65px;
        }
      }
      #Access {
        h3 {
          margin-bottom: 25px;
        }
      }
    }
  }
#PageContact.pageIndex {
    #Main {
      #ContactForm {
        table {
          tr {
            &:nth-of-type(4) {
              td {
                margin-bottom: 35px;
                padding-top: 10px;
                label:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  #PageContact.pageConfirm {
    #Main {
      #ContactForm {
        .boxBtn {
          margin-bottom: 25px;
        }
        input.reset {
          color: $color-b2;
        }
      }
    }
  }
/*##### English #####*/
  #PageEn.pageIndex {
    #MainImg {
      #MainImgInner {
        height: 600px;
        background-image: url(../img/contents/top/mainimg_sp.jpg);
      }
    }
    #Main {
      margin-bottom: 115px;
      h4 {
        font-size: 32px;
        line-height: 1.22;
        letter-spacing: 0.02em;
      }
      p {
        margin-bottom: 30px;
        text-align: left;
      }
      figure {
        img {
          width: 100%;
        }
      }
      #About {
        margin-bottom: 50px;
        .innerBasic {
          margin-left: 0;
          margin-right: 0;
        }
        h3 {
          margin-bottom: 10px;
        }
        h4 {
          text-align: center;
        }
        .contSubBox {
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 55px;
        }
        figure {
          margin: 33px 0;
        }
      }
      #Research {
        h4 {
          margin-bottom: 27px;
        }
        .contSubBox {
          ul.listBox {
            padding: 35px 20px 20px;
            li {
              margin-left: 20px;
              margin-bottom: 14px;
              line-height: 1.7;
              &::before {
                top: 13px;
                left: -20px;
              }
            }
          }
          &:nth-of-type(1) {
            margin-bottom: 50px;
            figure {
              margin: 33px 0;
            }
          }
          &:nth-of-type(2) {
            margin-bottom: 30px;
            figure {
              margin: 33px 0;
            }
          }
        }
      }
    }
  }
}

@media print,
screen and (min-width: 380px) and (max-width: 767px) {
  #PageMember.pageIndex #Main .contSubBox .memberList dl dd.cmt {
    margin-top: 30px;
  }
}
@media print,
screen and (max-width: 335px) {
  #Page.pageIndex #Main #ContBox03 .contSubBox img,
  #PageProject.pageIndex #Main .box02 img {
    width: 285px;
  }
  #MainImg #MainImgInner h2 {
    font-size: 50px;
  }
  #PageAchievement.pageIndex #Main .contBox .contSubBox> ol li div p {
    word-break: break-all;
  }
  #PageJoin.pageIndex #Main #Pharmacist h3::after {
    letter-spacing: -0.02em;
    font-size: 70px;
  }
  #PageJoin.pageIndex #Main #Student .contSubBox h4 span {
    white-space: normal;
  }
  #PageJoin.pageIndex #Main .contBox .contSubBox h4::before {
    content: none;
  }
}