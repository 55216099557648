@charset "UTF-8";
@import url(base.css);
@import url(common.css);
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/
/*JP,EN*/
/*リンク・見出し・ライン・フッター*/
/*{}*/
/*薄い青*/
/*フッターライン*/
/*一番濃い青*/
/*テキスト基本*/
/*補足,メンバー課程*/
/*プレイスホルダ*/
/*グレー背景*/
/*枠線*/
/*##### 404 #####*/
#Page404 #MainImg #MainImgInner {
  background-image: url(../img/contents/about/mainimg.jpg); }
#Page404 .contBox {
  text-align: center; }
  #Page404 .contBox p {
    text-align: center; }

/*##### Top #####*/
#Page.pageIndex #MainImg {
  margin-bottom: 0; }
#Page.pageIndex #Main #InfomationBox {
  background-color: #205edb; }
  #Page.pageIndex #Main #InfomationBox h2 {
    text-align: center;
    color: #fff; }
  #Page.pageIndex #Main #InfomationBox ul li a {
    padding: 0;
    background: none;
    color: #fff; }
    #Page.pageIndex #Main #InfomationBox ul li a dl dt {
      text-align: left; }
      #Page.pageIndex #Main #InfomationBox ul li a dl dt span {
        margin-left: 0;
        font-weight: 500; }
    #Page.pageIndex #Main #InfomationBox ul li a dl dd {
      text-align: left;
      font-weight: 400;
      color: #fff; }
#Page.pageIndex #Main .contBox h2 {
  text-align: center;
  color: #205edb; }
#Page.pageIndex #Main #ContBox01 .txtBox .boxBtn {
  text-align: left; }
#Page.pageIndex #Main #ContBox02 ul.tabList {
  display: flex; }
  #Page.pageIndex #Main #ContBox02 ul.tabList li {
    padding-left: 15px;
    color: #205edb;
    cursor: pointer; }
    #Page.pageIndex #Main #ContBox02 ul.tabList li.is-active {
      background-image: url(../img/icon_09.png);
      background-position: left center;
      background-size: 7px auto;
      background-repeat: no-repeat; }
#Page.pageIndex #Main #ContBox02 .tab-content {
  display: none; }
  #Page.pageIndex #Main #ContBox02 .tab-content.is-show {
    display: block; }

/*##### About #####*/
#PageAbout.pageIndex #Main #Research h4 {
  text-align: center; }
#PageAbout.pageIndex #Main #Research h5 {
  text-align: center;
  font-size: 36px;
  color: #205edb; }
  #PageAbout.pageIndex #Main #Research h5 span {
    position: relative;
    padding: 0 60px;
    display: inline-block; }
    #PageAbout.pageIndex #Main #Research h5 span::before, #PageAbout.pageIndex #Main #Research h5 span::after {
      position: absolute;
      top: 50%;
      display: inline-block;
      content: "";
      width: 44px;
      height: 1px;
      background-color: #205edb; }
    #PageAbout.pageIndex #Main #Research h5 span::before {
      left: 0; }
    #PageAbout.pageIndex #Main #Research h5 span::after {
      right: 0; }
#PageAbout.pageIndex #Main #Research .txtBox h4 {
  text-align: left; }
  #PageAbout.pageIndex #Main #Research .txtBox h4 span {
    background: linear-gradient(transparent 65%, #eef7ff 65%); }
#PageAbout.pageIndex #Main #Research .contSubBox ul.listBox {
  background-color: #f7f7f7; }
  #PageAbout.pageIndex #Main #Research .contSubBox ul.listBox li {
    position: relative; }
    #PageAbout.pageIndex #Main #Research .contSubBox ul.listBox li::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 10px;
      height: 2px;
      background-color: #205edb; }

/*##### News #####*/
#PageNews.pageIndex #Main .contSubBox h3 {
  text-align: center; }
  #PageNews.pageIndex #Main .contSubBox h3 span {
    position: relative;
    padding: 0 60px;
    display: inline-block; }
    #PageNews.pageIndex #Main .contSubBox h3 span::before, #PageNews.pageIndex #Main .contSubBox h3 span::after {
      position: absolute;
      top: 50%;
      display: inline-block;
      content: "";
      width: 44px;
      height: 1px;
      background-color: #205edb; }
    #PageNews.pageIndex #Main .contSubBox h3 span::before {
      left: 0; }
    #PageNews.pageIndex #Main .contSubBox h3 span::after {
      right: 0; }
#PageNews.pageIndex #Main .contSubBox p.noPost {
  text-align: center;
  font-size: 20px; }

#PageNews.pageEntry #Main .entryBoxWall {
  background-color: #f7f7f7; }
  #PageNews.pageEntry #Main .entryBoxWall .title {
    text-align: left; }
    #PageNews.pageEntry #Main .entryBoxWall .title span {
      display: block;
      font-size: 16px;
      font-family: "Noto Sans JP", "游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif; }
      #PageNews.pageEntry #Main .entryBoxWall .title span b {
        margin-left: 20px;
        font-weight: 600; }
        #PageNews.pageEntry #Main .entryBoxWall .title span b::before, #PageNews.pageEntry #Main .entryBoxWall .title span b::after {
          color: #92c5f5;
          font-weight: 500; }
        #PageNews.pageEntry #Main .entryBoxWall .title span b::before {
          content: "{ "; }
        #PageNews.pageEntry #Main .entryBoxWall .title span b::after {
          content: " }"; }
#PageNews.pageEntry #Main .pageNation ul {
  position: relative;
  height: 50px; }
  #PageNews.pageEntry #Main .pageNation ul li {
    position: absolute; }
    #PageNews.pageEntry #Main .pageNation ul li a {
      font-size: 14px; }
    #PageNews.pageEntry #Main .pageNation ul li.list {
      top: 0;
      left: 50%;
      transform: translateX(-50%); }
      #PageNews.pageEntry #Main .pageNation ul li.list a {
        display: block;
        width: 140px;
        height: 50px;
        border: solid 1px #205edb;
        line-height: 50px;
        text-align: center; }
    #PageNews.pageEntry #Main .pageNation ul li.pre, #PageNews.pageEntry #Main .pageNation ul li.next {
      top: 15px; }
      #PageNews.pageEntry #Main .pageNation ul li.pre a, #PageNews.pageEntry #Main .pageNation ul li.next a {
        background-size: 26px auto;
        background-repeat: no-repeat; }
    #PageNews.pageEntry #Main .pageNation ul li.pre {
      left: 0; }
      #PageNews.pageEntry #Main .pageNation ul li.pre a {
        padding-left: 34px;
        background-image: url(../img/icon_12.png);
        background-position: left bottom 8px; }
    #PageNews.pageEntry #Main .pageNation ul li.next {
      right: 0; }
      #PageNews.pageEntry #Main .pageNation ul li.next a {
        padding-right: 34px;
        background-image: url(../img/icon_13.png);
        background-position: right bottom 8px; }

/*##### Project #####*/
#PageProject.pageCategory #Main .contBox h3 {
  text-align: center; }
  #PageProject.pageCategory #Main .contBox h3 span {
    position: relative;
    padding: 0 68px;
    display: inline-block; }
    #PageProject.pageCategory #Main .contBox h3 span::before, #PageProject.pageCategory #Main .contBox h3 span::after {
      position: absolute;
      top: 50%;
      display: inline-block;
      content: "";
      width: 45px;
      height: 1px;
      background-color: #205edb; }
    #PageProject.pageCategory #Main .contBox h3 span::before {
      left: 0; }
    #PageProject.pageCategory #Main .contBox h3 span::after {
      right: 0; }

#PageProject.pageEntry #Main #FirstBox {
  position: relative;
  text-align: center; }
  #PageProject.pageEntry #Main #FirstBox::before {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    content: "Project";
    color: #eef7ff;
    font-family: "Cormorant", serif;
    line-height: 1em;
    z-index: -1; }
  #PageProject.pageEntry #Main #FirstBox p.caption {
    text-align: right; }
#PageProject.pageEntry #Main .contSubBox > h4 {
  font-size: 32px; }
  #PageProject.pageEntry #Main .contSubBox > h4 b {
    position: relative;
    display: block;
    font-family: "Cormorant", serif; }
    #PageProject.pageEntry #Main .contSubBox > h4 b::after {
      position: absolute;
      top: 50%;
      left: 0;
      content: "";
      width: 100%;
      height: 1px;
      background-color: #205edb;
      z-index: -1; }
    #PageProject.pageEntry #Main .contSubBox > h4 b span {
      display: inline-block;
      background-color: #fff; }

/*##### Achievement #####*/
#PageAchievement.pageIndex #Main .contBox {
  margin-bottom: 55px; }
  #PageAchievement.pageIndex #Main .contBox .contSubBox {
    margin-bottom: 20px;
    background-color: #f7f7f7; }
    #PageAchievement.pageIndex #Main .contBox .contSubBox > h4 {
      text-align: center; }
      #PageAchievement.pageIndex #Main .contBox .contSubBox > h4 span {
        position: relative;
        display: inline-block; }
        #PageAchievement.pageIndex #Main .contBox .contSubBox > h4 span::before, #PageAchievement.pageIndex #Main .contBox .contSubBox > h4 span::after {
          position: absolute;
          top: 50%;
          display: inline-block;
          content: "";
          width: 45px;
          height: 1px;
          background-color: #205edb; }
        #PageAchievement.pageIndex #Main .contBox .contSubBox > h4 span::before {
          left: 0; }
        #PageAchievement.pageIndex #Main .contBox .contSubBox > h4 span::after {
          right: 0; }
    #PageAchievement.pageIndex #Main .contBox .contSubBox > ol {
      counter-reset: number01;
      list-style: none; }
      #PageAchievement.pageIndex #Main .contBox .contSubBox > ol li {
        position: relative;
        padding-left: 28px; }
        #PageAchievement.pageIndex #Main .contBox .contSubBox > ol li::before {
          position: absolute;
          top: -2px;
          left: 0;
          counter-increment: number01;
          content: counter(number01) ". ";
          font-size: 20px;
          letter-spacing: -0.04em;
          font-family: "Shippori Mincho", "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
          color: #205edb; }
        #PageAchievement.pageIndex #Main .contBox .contSubBox > ol li div b {
          font-weight: 600; }
        #PageAchievement.pageIndex #Main .contBox .contSubBox > ol li div i {
          font-style: italic; }

/*##### Member #####*/
#PageMember.pageIndex #Main .contSubBox h4 {
  text-align: center;
  font-size: 28px; }
  #PageMember.pageIndex #Main .contSubBox h4 span {
    position: relative;
    display: inline-block;
    padding: 0 68px; }
    #PageMember.pageIndex #Main .contSubBox h4 span::before, #PageMember.pageIndex #Main .contSubBox h4 span::after {
      position: absolute;
      top: 50%;
      display: inline-block;
      content: "";
      width: 44px;
      height: 1px;
      background-color: #205edb; }
    #PageMember.pageIndex #Main .contSubBox h4 span::before {
      left: 0; }
    #PageMember.pageIndex #Main .contSubBox h4 span::after {
      right: 0; }
#PageMember.pageIndex #Main .contSubBox dl {
  font-family: "Shippori Mincho", "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-weight: 800; }
  #PageMember.pageIndex #Main .contSubBox dl dt {
    font-size: 20px; }
    #PageMember.pageIndex #Main .contSubBox dl dt span {
      font-size: 16px;
      letter-spacing: 0; }
  #PageMember.pageIndex #Main .contSubBox dl dd:first-of-type {
    color: #999999; }
  #PageMember.pageIndex #Main .contSubBox dl dd.cmt {
    font-family: "Noto Sans JP", "游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
    font-weight: 500; }
  #PageMember.pageIndex #Main .contSubBox dl dd ul li {
    position: relative;
    margin-left: 16px; }
    #PageMember.pageIndex #Main .contSubBox dl dd ul li::before {
      position: absolute;
      top: 12px;
      left: -16px;
      content: "";
      width: 8px;
      height: 1px;
      background-color: #205edb; }

/*##### Join #####*/
#PageJoin.pageIndex #Main .contBox h3 {
  position: relative; }
  #PageJoin.pageIndex #Main .contBox h3::after {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    font-family: "Cormorant", serif;
    line-height: 1em;
    color: #eef7ff; }
#PageJoin.pageIndex #Main .contBox > .innerBasic p {
  text-align: center; }
#PageJoin.pageIndex #Main .contBox .contSubBox h4 {
  width: 100%;
  position: relative; }
  #PageJoin.pageIndex #Main .contBox .contSubBox h4::before {
    position: absolute;
    top: 50%;
    left: 0;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #205edb;
    z-index: -1; }
  #PageJoin.pageIndex #Main .contBox .contSubBox h4 span {
    display: inline-block;
    background-color: #fff; }
#PageJoin.pageIndex #Main #Student h3::after {
  content: "Student"; }
#PageJoin.pageIndex #Main #Pharmacist h3::after {
  content: "Pharmacist"; }
#PageJoin.pageIndex #Main #Pharmacist ul {
  font-family: "Shippori Mincho", "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif; }
  #PageJoin.pageIndex #Main #Pharmacist ul li {
    background-color: #f7f7f7;
    text-align: center;
    font-size: 24px;
    color: #205edb; }
#PageJoin.pageIndex #Main #PartTime h3::after {
  content: "Part-time";
  white-space: nowrap; }

/*##### Contact #####*/
#PageContact #Main p {
  text-align: center; }
#PageContact #Main #ContactForm .contSubBox {
  background-color: #f7f7f7; }

#PageContact.pageConfirm #Main #ContactForm #ErrorBox {
  margin-bottom: 50px; }
  #PageContact.pageConfirm #Main #ContactForm #ErrorBox p {
    margin-bottom: 10px; }

#PageContact.pageThanks #Main #ContactForm h4 {
  text-align: center;
  margin-bottom: 30px; }

/*##### About #####*/
#PageEn.pageIndex #Main #Research h4 {
  text-align: center; }
#PageEn.pageIndex #Main #Research .txtBox h4 {
  text-align: left; }
  #PageEn.pageIndex #Main #Research .txtBox h4 span {
    background: linear-gradient(transparent 65%, #eef7ff 65%); }

/*##### MainImg #####*/
#PageAbout #MainImg #MainImgInner {
  background-image: url(../img/contents/about/mainimg.jpg); }

#PageNews #MainImg #MainImgInner {
  background-image: url(../img/contents/news/mainimg.jpg); }

#PageProject #MainImg #MainImgInner {
  background-image: url(../img/contents/project/mainimg.jpg); }

#PageAchievement #MainImg #MainImgInner {
  background-image: url(../img/contents/achievement/mainimg.jpg); }

#PageMember #MainImg #MainImgInner {
  background-image: url(../img/contents/member/mainimg.jpg); }

#PageJoin #MainImg #MainImgInner {
  background-image: url(../img/contents/join/mainimg.jpg); }

#PageContact #MainImg #MainImgInner {
  background-image: url(../img/contents/contact/mainimg.jpg); }

@media print, screen and (min-width: 768px) {
  /*##### Top #####*/
  #Page.pageIndex #MainImg {
    padding-top: 0; }
    #Page.pageIndex #MainImg #MainImgInner {
      height: 780px;
      position: relative;
      width: 100%;
      padding-top: 125px;
      overflow: hidden; }
      #Page.pageIndex #MainImg #MainImgInner video {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  #Page.pageIndex #InfomationBox {
    padding: 15px 0 25px 0; }
    #Page.pageIndex #InfomationBox .innerBasic {
      width: 100%;
      max-width: 1040px; }
    #Page.pageIndex #InfomationBox h2 {
      font-size: 36px;
      margin-bottom: 10px; }
    #Page.pageIndex #InfomationBox ul {
      margin-bottom: 0; }
      #Page.pageIndex #InfomationBox ul li a dl dt {
        width: 175px; }
      #Page.pageIndex #InfomationBox ul li a dl dd {
        width: calc(100% - 175px); }
  #Page.pageIndex #Main .contBox h2 {
    font-size: 70px;
    line-height: 1em; }
  #Page.pageIndex #Main #ContBox01 {
    margin: 0 auto;
    margin-top: 120px;
    margin-bottom: 110px;
    width: 100%;
    max-width: 1440px;
    background-image: url(../img/contents/top/img_01-pc.jpg);
    background-position: left 5px;
    background-repeat: no-repeat;
    background-size: 100% auto; }
    #Page.pageIndex #Main #ContBox01 .txtBox {
      margin-left: 48.3%;
      width: 51.7%;
      max-width: 620px; }
      #Page.pageIndex #Main #ContBox01 .txtBox h2 {
        margin-top: -5px;
        margin-bottom: 20px;
        text-align: left; }
      #Page.pageIndex #Main #ContBox01 .txtBox h3 {
        margin-bottom: 20px;
        text-align: left;
        line-height: 1.4;
        font-size: 36px; }
      #Page.pageIndex #Main #ContBox01 .txtBox p {
        margin-bottom: 20px; }
      #Page.pageIndex #Main #ContBox01 .txtBox .boxBtn {
        margin-top: 40px; }
  #Page.pageIndex #Main #ContBox02 {
    margin-bottom: 100px; }
    #Page.pageIndex #Main #ContBox02 .innerBasic {
      width: 100%;
      max-width: 1000px; }
    #Page.pageIndex #Main #ContBox02 h2 {
      margin-bottom: 30px; }
    #Page.pageIndex #Main #ContBox02 ul.tabList {
      justify-content: center;
      margin-bottom: 35px; }
      #Page.pageIndex #Main #ContBox02 ul.tabList li {
        margin-right: 45px; }
        #Page.pageIndex #Main #ContBox02 ul.tabList li:last-of-type {
          margin-right: 0; }
        #Page.pageIndex #Main #ContBox02 ul.tabList li:hover {
          opacity: 0.6; }
  #Page.pageIndex #Main .wallBox {
    width: 100%;
    height: 320px;
    background-image: url(../img/contents/top/bg.jpg);
    background-size: cover;
    background-position: center; }
  #Page.pageIndex #Main #ContBox03 {
    margin-top: 90px; }
    #Page.pageIndex #Main #ContBox03 h2 {
      margin-bottom: 50px; }
    #Page.pageIndex #Main #ContBox03 ul {
      margin-bottom: 5px; }
    #Page.pageIndex #Main #ContBox03 .contSubBox {
      margin-top: 90px;
      text-align: center; }
      #Page.pageIndex #Main #ContBox03 .contSubBox img {
        border: solid 1px #e0e0e0;
        width: 370px;
        height: auto; }
  #Page.pageIndex #Main #Access {
    margin-top: 120px;
    margin-bottom: 160px; }
    #Page.pageIndex #Main #Access .add h2 {
      margin-bottom: 30px;
      text-align: left; }

  /*##### About #####*/
  #PageAbout.pageIndex .linkBox {
    margin-bottom: 100px; }
  #PageAbout.pageIndex #Main {
    margin-bottom: 230px; }
    #PageAbout.pageIndex #Main #About {
      margin-bottom: 100px; }
      #PageAbout.pageIndex #Main #About h3 {
        margin-bottom: 15px; }
      #PageAbout.pageIndex #Main #About h4 {
        text-align: center; }
      #PageAbout.pageIndex #Main #About figure {
        margin: 45px 0 55px;
        height: 386px;
        width: 100%;
        background-image: url(../img/contents/about/img_01-pc.jpg);
        background-position: center top;
        background-repeat: no-repeat; }
        #PageAbout.pageIndex #Main #About figure img {
          display: none; }
      #PageAbout.pageIndex #Main #About .contSubBox {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: 85px; }
        #PageAbout.pageIndex #Main #About .contSubBox p {
          margin-bottom: 25px; }
    #PageAbout.pageIndex #Main #Research h3 {
      margin-bottom: 40px; }
    #PageAbout.pageIndex #Main #Research .contSubBox {
      margin-bottom: 110px; }
      #PageAbout.pageIndex #Main #Research .contSubBox:nth-of-type(1), #PageAbout.pageIndex #Main #Research .contSubBox:nth-of-type(2) {
        margin: 0 auto;
        width: 100%;
        max-width: 1440px;
        background-repeat: no-repeat;
        background-size: 100% auto; }
      #PageAbout.pageIndex #Main #Research .contSubBox:nth-of-type(1) {
        margin-bottom: 85px;
        background-image: url(../img/contents/about/img_02-pc.jpg);
        background-position: right top; }
      #PageAbout.pageIndex #Main #Research .contSubBox:nth-of-type(2) {
        margin-bottom: 80px;
        background-image: url(../img/contents/about/img_03-pc.jpg);
        background-position: left top; }
        #PageAbout.pageIndex #Main #Research .contSubBox:nth-of-type(2) .txtBox {
          margin-left: 48.3%;
          padding-bottom: 46px; }
      #PageAbout.pageIndex #Main #Research .contSubBox:nth-of-type(4) .innerBasic {
        max-width: 1200px;
        padding: 0; }
      #PageAbout.pageIndex #Main #Research .contSubBox h4 {
        line-height: 1.4; }
      #PageAbout.pageIndex #Main #Research .contSubBox h5 {
        margin-bottom: 45px; }
      #PageAbout.pageIndex #Main #Research .contSubBox .txtBox {
        width: 51.7%;
        max-width: 620px; }
        #PageAbout.pageIndex #Main #Research .contSubBox .txtBox h4 {
          margin-bottom: 35px; }
      #PageAbout.pageIndex #Main #Research .contSubBox ul.listBox {
        display: flex;
        flex-wrap: wrap;
        margin-top: 45px;
        padding-top: 65px;
        padding-bottom: 60px;
        padding-right: 6.6%;
        padding-left: 6.6%; }
        #PageAbout.pageIndex #Main #Research .contSubBox ul.listBox li {
          margin-bottom: 14px;
          width: calc(50% - 50px);
          line-height: 1.75;
          margin-left: 30px; }
          #PageAbout.pageIndex #Main #Research .contSubBox ul.listBox li::before {
            top: 15px;
            left: -30px; }
          #PageAbout.pageIndex #Main #Research .contSubBox ul.listBox li:nth-child(odd) {
            margin-right: 40px; }

  /*##### News #####*/
  #PageNews #Main .linkBox {
    margin-bottom: 100px; }
    #PageNews #Main .linkBox ul:first-of-type li a {
      width: 200px; }
  #PageNews #Main .contBox .innerBasic {
    max-width: 1200px;
    padding: 0; }

  #PageNews.pageIndex #Main {
    margin-bottom: 180px; }
    #PageNews.pageIndex #Main .contSubBox h3 {
      margin-bottom: 30px;
      font-size: 36px; }
    #PageNews.pageIndex #Main .contSubBox.box01 {
      margin-bottom: 90px; }

  #PageNews.pageCategory #Main,
  #PageNews.pageArchive #Main {
    margin-bottom: 150px; }
    #PageNews.pageCategory #Main h3,
    #PageNews.pageArchive #Main h3 {
      font-size: 60px;
      line-height: 1.25em;
      margin-bottom: 45px; }

  #PageNews.pageEntry #Main {
    margin-bottom: 180px; }
    #PageNews.pageEntry #Main .entryBoxWall {
      margin-bottom: 30px;
      padding: 95px 100px 90px 100px; }
      #PageNews.pageEntry #Main .entryBoxWall .title {
        margin-bottom: 45px;
        font-size: 32px; }
        #PageNews.pageEntry #Main .entryBoxWall .title span {
          margin-bottom: 15px; }
    #PageNews.pageEntry #Main .pageNation ul li.list a:hover {
      text-decoration: none;
      opacity: 0.6; }

  /*##### Project #####*/
  #PageProject.pageIndex #Main {
    margin-top: 160px;
    margin-bottom: 224px; }
    #PageProject.pageIndex #Main ul.projectList {
      margin-bottom: 75px; }
    #PageProject.pageIndex #Main .box02 {
      margin-top: 90px;
      text-align: center; }
      #PageProject.pageIndex #Main .box02 img {
        border: solid 1px #e0e0e0;
        width: 370px;
        height: auto; }

  #PageProject.pageCategory #TopicPath {
    margin-bottom: 65px; }
  #PageProject.pageCategory #Main {
    margin-bottom: 213px; }
    #PageProject.pageCategory #Main .contBox h3 {
      margin-bottom: 50px;
      font-size: 36px; }
    #PageProject.pageCategory #Main .contBox ul {
      margin-bottom: 75px; }

  #PageProject.pageEntry #Main {
    margin-top: 95px;
    margin-bottom: 150px; }
    #PageProject.pageEntry #Main .contBox:nth-of-type(1) {
      margin-bottom: 100px; }
      #PageProject.pageEntry #Main .contBox:nth-of-type(1) .innerBasic {
        width: 100%;
        max-width: 1040px;
        padding-right: 20px;
        padding-left: 20px; }
    #PageProject.pageEntry #Main #FirstBox {
      padding-top: 135px; }
      #PageProject.pageEntry #Main #FirstBox::before {
        font-size: 160px; }
      #PageProject.pageEntry #Main #FirstBox h3 {
        margin-bottom: 50px;
        line-height: 1.25; }
      #PageProject.pageEntry #Main #FirstBox img {
        margin-bottom: 10px;
        width: 100%;
        max-width: 800px; }
      #PageProject.pageEntry #Main #FirstBox p.caption {
        font-size: 12px; }
    #PageProject.pageEntry #Main .contSubBox {
      margin-bottom: 70px; }
      #PageProject.pageEntry #Main .contSubBox > h4 {
        margin-bottom: 25px; }
        #PageProject.pageEntry #Main .contSubBox > h4 b {
          margin-bottom: 0px;
          font-size: 28px; }
          #PageProject.pageEntry #Main .contSubBox > h4 b span {
            padding-right: 20px; }
    #PageProject.pageEntry #Main #ImageBox {
      margin-top: 110px;
      margin-bottom: 80px; }
      #PageProject.pageEntry #Main #ImageBox img {
        margin-bottom: 40px;
        max-width: 100%; }
        #PageProject.pageEntry #Main #ImageBox img:last-of-type {
          margin-bottom: 0; }
    #PageProject.pageEntry #Main .linkBox {
      margin-top: 115px; }

  /*##### Achievement #####*/
  #PageAchievement.pageIndex #Main .innerBasic {
    padding: 0;
    max-width: 1200px; }
  #PageAchievement.pageIndex #Main .linkBox {
    margin-bottom: 85px; }
    #PageAchievement.pageIndex #Main .linkBox ul li a {
      width: 160px; }
  #PageAchievement.pageIndex #Main .contBox {
    margin-bottom: 100px; }
    #PageAchievement.pageIndex #Main .contBox > h3 {
      margin-bottom: 45px;
      font-size: 54px; }
    #PageAchievement.pageIndex #Main .contBox .contSubBox {
      margin-bottom: 30px;
      padding-top: 90px;
      padding-bottom: 75px;
      padding-right: 8.3%;
      padding-left: 8.3%; }
      #PageAchievement.pageIndex #Main .contBox .contSubBox > h4 {
        margin-bottom: 35px;
        font-size: 34px; }
        #PageAchievement.pageIndex #Main .contBox .contSubBox > h4 span {
          padding: 0 70px; }
      #PageAchievement.pageIndex #Main .contBox .contSubBox > ol li {
        margin-bottom: 22px; }
        #PageAchievement.pageIndex #Main .contBox .contSubBox > ol li p {
          text-align: left; }

  /*##### Member #####*/
  #PageMember.pageIndex #TopicPath {
    margin-bottom: 100px; }
  #PageMember.pageIndex #Main .innerBasic {
    padding: 0;
    max-width: 1200px; }
  #PageMember.pageIndex #Main .contSubBox {
    margin-bottom: 100px;
    padding: 0 20px; }
    #PageMember.pageIndex #Main .contSubBox h3 {
      margin-bottom: 45px;
      font-size: 56px; }
    #PageMember.pageIndex #Main .contSubBox h4 {
      margin-top: -5px;
      margin-bottom: 75px; }
    #PageMember.pageIndex #Main .contSubBox .memberList {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 60px; }
      #PageMember.pageIndex #Main .contSubBox .memberList dl {
        position: relative;
        margin-bottom: 60px;
        padding-top: 18px;
        padding-left: 220px;
        width: calc( 50% - 20px);
        min-height: 200px; }
        #PageMember.pageIndex #Main .contSubBox .memberList dl:nth-child(odd) {
          margin-right: 40px; }
        #PageMember.pageIndex #Main .contSubBox .memberList dl dt {
          line-height: 1.4; }
        #PageMember.pageIndex #Main .contSubBox .memberList dl dd ul {
          margin-top: 3px; }
          #PageMember.pageIndex #Main .contSubBox .memberList dl dd ul li {
            line-height: 1.4; }
        #PageMember.pageIndex #Main .contSubBox .memberList dl dd.cmt {
          margin-top: 14px;
          line-height: 1.75; }
        #PageMember.pageIndex #Main .contSubBox .memberList dl dd.photo {
          position: absolute;
          top: 0;
          left: 0; }
          #PageMember.pageIndex #Main .contSubBox .memberList dl dd.photo img {
            width: 200px;
            height: auto; }
      #PageMember.pageIndex #Main .contSubBox .memberList.column4 dl {
        padding: 0;
        width: calc((100% - 120px) / 4);
        margin-right: 40px;
        min-height: auto; }
        #PageMember.pageIndex #Main .contSubBox .memberList.column4 dl dt span {
          display: block; }
        #PageMember.pageIndex #Main .contSubBox .memberList.column4 dl:nth-child(4n) {
          margin-right: 0; }
      #PageMember.pageIndex #Main .contSubBox .memberList.noPhoto dl {
        padding: 0;
        min-height: auto; }
        #PageMember.pageIndex #Main .contSubBox .memberList.noPhoto dl dt span {
          display: block; }
      #PageMember.pageIndex #Main .contSubBox .memberList::after {
        content: "";
        display: block;
        clear: both; }
  #PageMember.pageIndex #Main .subBox02 .memberList dl.type01 {
    padding-top: 40px; }
  #PageMember.pageIndex #Main .subBox03 .memberList:nth-of-type(1) {
    margin-bottom: 0; }
    #PageMember.pageIndex #Main .subBox03 .memberList:nth-of-type(1) dl {
      padding-top: 10px; }
  #PageMember.pageIndex #Main .subBox03 h4:nth-of-type(2) {
    margin-top: -8px;
    margin-bottom: 50px; }
  #PageMember.pageIndex #Main .subBox05 .memberList.noPhoto dl dt span {
    display: inline; }

  /*##### Join #####*/
  #PageJoin.pageIndex .linkBox {
    margin-bottom: 55px; }
    #PageJoin.pageIndex .linkBox ul li:nth-of-type(1) a {
      width: 200px; }
    #PageJoin.pageIndex .linkBox ul li:nth-of-type(2) a {
      padding-left: 60px;
      padding-right: 60px; }
  #PageJoin.pageIndex #Main {
    margin-bottom: 195px; }
    #PageJoin.pageIndex #Main .contBox {
      margin-bottom: 60px; }
      #PageJoin.pageIndex #Main .contBox h3 {
        padding-top: 108px;
        margin-bottom: 45px;
        font-size: 56px;
        line-height: 1.3; }
        #PageJoin.pageIndex #Main .contBox h3::after {
          font-size: 160px;
          letter-spacing: 0.02em; }
      #PageJoin.pageIndex #Main .contBox .contSubBox .innerBasic {
        width: 100%;
        max-width: 1040px; }
      #PageJoin.pageIndex #Main .contBox .contSubBox h4 {
        margin-bottom: 25px; }
        #PageJoin.pageIndex #Main .contBox .contSubBox h4 span {
          padding-right: 25px; }
    #PageJoin.pageIndex #Main #Student .contSubBox {
      margin-bottom: 90px; }
      #PageJoin.pageIndex #Main #Student .contSubBox:nth-of-type(1) {
        margin-top: 70px; }
      #PageJoin.pageIndex #Main #Student .contSubBox:nth-of-type(2), #PageJoin.pageIndex #Main #Student .contSubBox:nth-of-type(3) {
        position: relative;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        margin-bottom: 80px;
        background-size: 100% auto;
        background-repeat: no-repeat;
        z-index: -1; }
        #PageJoin.pageIndex #Main #Student .contSubBox:nth-of-type(2) .txtBox, #PageJoin.pageIndex #Main #Student .contSubBox:nth-of-type(3) .txtBox {
          padding-top: 30px;
          width: 52%;
          max-width: 520px;
          min-height: 430px; }
      #PageJoin.pageIndex #Main #Student .contSubBox:nth-of-type(2) {
        background-image: url(../img/contents/join/img_01-pc.jpg);
        background-position: left top; }
        #PageJoin.pageIndex #Main #Student .contSubBox:nth-of-type(2) .txtBox {
          margin-left: 48%;
          padding-bottom: 1px; }
      #PageJoin.pageIndex #Main #Student .contSubBox:nth-of-type(3) {
        background-image: url(../img/contents/join/img_02-pc.jpg);
        background-position: right top; }
        #PageJoin.pageIndex #Main #Student .contSubBox:nth-of-type(3) h4 span {
          padding-right: 15px; }
        #PageJoin.pageIndex #Main #Student .contSubBox:nth-of-type(3) .boxBtn {
          margin-top: 47px;
          padding-bottom: 18px;
          text-align: left; }
      #PageJoin.pageIndex #Main #Student .contSubBox:last-of-type {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding-bottom: 95px; }
    #PageJoin.pageIndex #Main #Pharmacist .innerBasic {
      padding-bottom: 95px; }
    #PageJoin.pageIndex #Main #Pharmacist ul {
      display: table;
      border-collapse: separate;
      margin: 55px 0; }
      #PageJoin.pageIndex #Main #Pharmacist ul li {
        width: 305px;
        height: 285px;
        padding: 0 10px;
        display: table-cell;
        vertical-align: middle;
        border-right: solid #fff 20px; }
        #PageJoin.pageIndex #Main #Pharmacist ul li:last-of-type {
          width: 285px;
          border-right: none; }
    #PageJoin.pageIndex #Main #Pharmacist p {
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
      margin-bottom: 25px; }
    #PageJoin.pageIndex #Main #PartTime .innerBasic {
      width: 100%;
      max-width: 1000px; }

  /*##### Contact #####*/
  #PageContact #Main {
    margin-bottom: 130px; }
    #PageContact #Main .innerBasic {
      padding: 0;
      max-width: 1200px; }
    #PageContact #Main .linkBox {
      margin-bottom: 85px; }
      #PageContact #Main .linkBox ul li:first-of-type {
        width: 200px; }
    #PageContact #Main #ContactForm {
      margin-bottom: 85px; }
      #PageContact #Main #ContactForm h3 {
        margin-bottom: 15px; }
      #PageContact #Main #ContactForm .contSubBox {
        margin-top: 45px;
        padding: 90px 0; }
    #PageContact #Main #Access .innerBasic {
      max-width: 1240px;
      padding: 0 20px; }
    #PageContact #Main #Access h3 {
      margin-bottom: 25px; }

  #PageContact.pageIndex #Main #ContactForm table tr:nth-of-type(4) th {
    padding-top: 0; }
  #PageContact.pageIndex #Main #ContactForm table tr:nth-of-type(4) td label:last-of-type {
    margin-bottom: 0; }

  #PageContact.pageConfirm #Main #ContactForm table tr th {
    padding-top: 0; }
  #PageContact.pageConfirm #Main #ContactForm .boxBtn {
    margin-bottom: 25px; }
  #PageContact.pageConfirm #Main #ContactForm input.reset {
    color: #205edb; }
    #PageContact.pageConfirm #Main #ContactForm input.reset:hover {
      text-decoration: underline; }

  /*##### English #####*/
  #PageEn.pageIndex #GlobalNav ul.language li a {
    color: #205edb; }
  #PageEn.pageIndex #GlobalNav ul.language li:first-of-type a {
    color: #7e99e6; }
  #PageEn.pageIndex #Footer #TextLink ul.language li a {
    color: #fff; }
  #PageEn.pageIndex #Footer #TextLink ul.language li:first-of-type a {
    color: #7e99e6; }
  #PageEn.pageIndex #Footer #UniversityInfo dl {
    width: auto;
    margin-right: 60px; }
    #PageEn.pageIndex #Footer #UniversityInfo dl dd {
      line-height: 1.7; }
  #PageEn.pageIndex #MainImg {
    padding-top: 0; }
    #PageEn.pageIndex #MainImg #MainImgInner {
      width: 100%;
      height: 780px;
      padding-top: 125px;
      overflow: hidden; }
      #PageEn.pageIndex #MainImg #MainImgInner video {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  #PageEn.pageIndex #Main {
    margin-bottom: 115px; }
    #PageEn.pageIndex #Main p {
      margin-bottom: 30px;
      text-align: left; }
    #PageEn.pageIndex #Main #About {
      margin-bottom: 100px; }
      #PageEn.pageIndex #Main #About h3 {
        margin-bottom: 20px; }
      #PageEn.pageIndex #Main #About h4 {
        text-align: center;
        line-height: 1.4; }
      #PageEn.pageIndex #Main #About figure {
        margin: 45px 0 55px;
        width: 100%;
        height: 386px;
        background-repeat: no-repeat;
        background-position: center top;
        background-image: url(../img/contents/about/img_01-pc.jpg); }
        #PageEn.pageIndex #Main #About figure img {
          display: none; }
      #PageEn.pageIndex #Main #About .contSubBox {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: 80px; }
    #PageEn.pageIndex #Main #Research h3 {
      margin-bottom: 40px; }
    #PageEn.pageIndex #Main #Research .contSubBox:nth-of-type(1), #PageEn.pageIndex #Main #Research .contSubBox:nth-of-type(2) {
      margin: 0 auto;
      width: 100%;
      max-width: 1440px;
      background-repeat: no-repeat;
      background-size: 100% auto; }
    #PageEn.pageIndex #Main #Research .contSubBox:nth-of-type(1) {
      margin-bottom: 85px;
      background-image: url(../img/contents/about/img_02-pc.jpg);
      background-position: right top; }
    #PageEn.pageIndex #Main #Research .contSubBox:nth-of-type(2) {
      background-image: url(../img/contents/about/img_03-pc.jpg);
      background-position: left top; }
      #PageEn.pageIndex #Main #Research .contSubBox:nth-of-type(2) .txtBox {
        margin-left: 48.3%;
        padding-bottom: 46px; }
    #PageEn.pageIndex #Main #Research .contSubBox h4 {
      line-height: 1.3;
      font-size: 40px; }
    #PageEn.pageIndex #Main #Research .contSubBox .txtBox {
      width: 51.7%;
      max-width: 620px; }
      #PageEn.pageIndex #Main #Research .contSubBox .txtBox h4 {
        margin-bottom: 30px; } }
@media screen and (min-width: 768px) and (max-width: 840px) {
  #PageEn.pageIndex #Footer #UniversityInfo dl {
    margin-right: 30px; } }
@media screen and (min-width: 768px) and (max-width: 1039px) {
  #PageMember.pageIndex #Main .contSubBox .memberList dl {
    width: 100%; } }
@media print, screen and (max-width: 767px) {
  /*##### Top #####*/
  #Page.pageIndex #MainImg #MainImgInner {
    height: 600px;
    background-image: url(../img/contents/top/mainimg_sp.jpg); }
  #Page.pageIndex #Main {
    margin-bottom: 105px; }
    #Page.pageIndex #Main h2 {
      font-size: 56px; }
    #Page.pageIndex #Main #InfomationBox {
      padding: 30px 0 25px 0; }
      #Page.pageIndex #Main #InfomationBox h2 {
        margin-bottom: 28px;
        font-size: 28px; }
      #Page.pageIndex #Main #InfomationBox ul {
        margin-bottom: 0; }
        #Page.pageIndex #Main #InfomationBox ul li dl dt {
          margin-bottom: 8px; }
        #Page.pageIndex #Main #InfomationBox ul li dl dd {
          line-height: 1.65; }
    #Page.pageIndex #Main #ContBox01 {
      margin-top: 30px;
      margin-bottom: 45px; }
      #Page.pageIndex #Main #ContBox01 h3 {
        margin-bottom: 15px;
        font-size: 28px;
        letter-spacing: -0.02em;
        line-height: 1.45; }
      #Page.pageIndex #Main #ContBox01 .txtBox {
        margin-bottom: 50px; }
        #Page.pageIndex #Main #ContBox01 .txtBox .boxBtn {
          margin-top: 32px; }
      #Page.pageIndex #Main #ContBox01 figure img {
        width: 100%; }
    #Page.pageIndex #Main #ContBox02 {
      margin-bottom: 75px; }
      #Page.pageIndex #Main #ContBox02 .innerBasic {
        margin-left: 0;
        margin-right: 0; }
        #Page.pageIndex #Main #ContBox02 .innerBasic .area {
          margin-left: 20px;
          margin-right: 20px; }
      #Page.pageIndex #Main #ContBox02 h2 {
        margin-bottom: 15px; }
      #Page.pageIndex #Main #ContBox02 .tab-content {
        margin-left: 20px;
        margin-right: 20px; }
      #Page.pageIndex #Main #ContBox02 ul.tabList {
        margin-bottom: 15px;
        padding-bottom: 5px;
        width: 100%;
        overflow-x: scroll; }
        #Page.pageIndex #Main #ContBox02 ul.tabList li {
          margin-right: 15px;
          flex-shrink: 0; }
          #Page.pageIndex #Main #ContBox02 ul.tabList li:first-of-type {
            margin-left: 20px; }
    #Page.pageIndex #Main .wallBox {
      width: 100%;
      height: 160px;
      background-size: cover;
      background-position: center; }
    #Page.pageIndex #Main #ContBox03 {
      margin-top: 45px; }
      #Page.pageIndex #Main #ContBox03 h2 {
        margin-bottom: 20px; }
      #Page.pageIndex #Main #ContBox03 .contSubBox {
        margin-top: 70px;
        text-align: center; }
        #Page.pageIndex #Main #ContBox03 .contSubBox img {
          border: solid 1px #e0e0e0;
          width: 335px;
          height: auto; }
    #Page.pageIndex #Main #Access {
      margin-top: 45px; }
      #Page.pageIndex #Main #Access h2 {
        text-align: left; }

  /*##### About #####*/
  #PageAbout.pageIndex .linkBox {
    margin-bottom: 30px; }
  #PageAbout.pageIndex #Main {
    margin-bottom: 115px; }
    #PageAbout.pageIndex #Main h4 {
      line-height: 1.5; }
    #PageAbout.pageIndex #Main figure img {
      width: 100%; }
    #PageAbout.pageIndex #Main #About {
      margin-bottom: 50px; }
      #PageAbout.pageIndex #Main #About .innerBasic {
        margin-left: 0;
        margin-right: 0; }
      #PageAbout.pageIndex #Main #About h3 {
        margin-bottom: 10px; }
      #PageAbout.pageIndex #Main #About h4 {
        text-align: center; }
      #PageAbout.pageIndex #Main #About .contSubBox {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 55px; }
      #PageAbout.pageIndex #Main #About figure {
        margin: 33px 0; }
    #PageAbout.pageIndex #Main #Research h4 {
      margin-bottom: 27px;
      line-height: 1.4; }
    #PageAbout.pageIndex #Main #Research .contSubBox ul.listBox {
      padding: 35px 20px 20px; }
      #PageAbout.pageIndex #Main #Research .contSubBox ul.listBox li {
        margin-left: 20px;
        margin-bottom: 14px;
        line-height: 1.7; }
        #PageAbout.pageIndex #Main #Research .contSubBox ul.listBox li::before {
          top: 13px;
          left: -20px; }
    #PageAbout.pageIndex #Main #Research .contSubBox:nth-of-type(1) {
      margin-bottom: 50px; }
      #PageAbout.pageIndex #Main #Research .contSubBox:nth-of-type(1) figure {
        margin: 33px 0; }
    #PageAbout.pageIndex #Main #Research .contSubBox:nth-of-type(2) {
      margin-bottom: 30px; }
      #PageAbout.pageIndex #Main #Research .contSubBox:nth-of-type(2) figure {
        margin: 33px 0; }
    #PageAbout.pageIndex #Main #Research .contSubBox:nth-of-type(3) {
      margin-bottom: 70px; }
      #PageAbout.pageIndex #Main #Research .contSubBox:nth-of-type(3) h5 {
        margin-bottom: 25px; }
      #PageAbout.pageIndex #Main #Research .contSubBox:nth-of-type(3) ul {
        margin-bottom: 25px; }
    #PageAbout.pageIndex #Main #Research .contSubBox:nth-of-type(4) .innerBasic {
      margin-left: 0;
      margin-right: 0; }

  /*##### News #####*/
  #PageNews #Main .linkBox {
    margin-bottom: 45px; }
  #PageNews #Main .contBox .innerBasic {
    margin: 0; }

  #PageNews.pageIndex #Main {
    margin-bottom: 130px; }
    #PageNews.pageIndex #Main .contSubBox h3 {
      margin-bottom: 35px;
      font-size: 28px; }
    #PageNews.pageIndex #Main .contSubBox.box01 {
      margin-bottom: 70px; }
    #PageNews.pageIndex #Main .contSubBox.box02 {
      margin-bottom: 50px; }

  #PageNews.pageCategory #Main,
  #PageNews.pageArchive #Main {
    margin-bottom: 120px; }
    #PageNews.pageCategory #Main h3,
    #PageNews.pageArchive #Main h3 {
      font-size: 42px;
      line-height: 1.25em;
      margin-bottom: 30px; }

  #PageNews.pageEntry #Main {
    margin-bottom: 115px; }
    #PageNews.pageEntry #Main .entryBoxWall {
      margin-bottom: 45px;
      padding: 58px 20px 35px 20px; }
      #PageNews.pageEntry #Main .entryBoxWall .title {
        margin-bottom: 33px;
        font-size: 26px;
        line-height: 1.35; }
        #PageNews.pageEntry #Main .entryBoxWall .title span {
          margin-bottom: 20px; }
      #PageNews.pageEntry #Main .entryBoxWall .entryBox p {
        line-height: 1.7; }
    #PageNews.pageEntry #Main .pageNation {
      margin-left: 20px;
      margin-right: 20px; }

  /*##### Project #####*/
  #PageProject.pageIndex #Main {
    margin-top: 75px;
    margin-bottom: 127px; }
    #PageProject.pageIndex #Main ul.projectList {
      margin-bottom: 36px; }
    #PageProject.pageIndex #Main .box02 {
      margin-top: 70px;
      text-align: center; }
      #PageProject.pageIndex #Main .box02 img {
        border: solid 1px #e0e0e0;
        width: 335px;
        height: auto; }

  #PageProject.pageCategory #Main {
    margin-bottom: 113px; }
    #PageProject.pageCategory #Main .contBox h3 {
      margin-bottom: 25px;
      font-size: 36px; }
    #PageProject.pageCategory #Main .contBox ul {
      margin-bottom: 5px; }

  #PageProject.pageEntry #Main {
    margin-top: 85px;
    margin-bottom: 150px; }
    #PageProject.pageEntry #Main .contBox:nth-of-type(1) {
      margin-bottom: 40px; }
    #PageProject.pageEntry #Main #FirstBox {
      padding-top: 60px; }
      #PageProject.pageEntry #Main #FirstBox::before {
        font-size: 80px; }
      #PageProject.pageEntry #Main #FirstBox h3 {
        margin-bottom: 35px;
        line-height: 1.25;
        font-size: 36px; }
      #PageProject.pageEntry #Main #FirstBox img {
        margin-bottom: 15px;
        width: 100%; }
      #PageProject.pageEntry #Main #FirstBox p.caption {
        font-size: 12px; }
    #PageProject.pageEntry #Main .contSubBox {
      margin-bottom: 55px; }
      #PageProject.pageEntry #Main .contSubBox > h4 {
        font-size: 26px;
        margin-bottom: 15px;
        letter-spacing: 0.02em; }
        #PageProject.pageEntry #Main .contSubBox > h4 b {
          margin-bottom: 0px;
          font-size: 24px; }
          #PageProject.pageEntry #Main .contSubBox > h4 b span {
            padding-right: 20px; }
    #PageProject.pageEntry #Main #ImageBox {
      margin-top: 65px; }
      #PageProject.pageEntry #Main #ImageBox img {
        margin-bottom: 20px;
        width: 100%; }
        #PageProject.pageEntry #Main #ImageBox img:last-of-type {
          margin-bottom: 0; }
    #PageProject.pageEntry #Main .linkBox {
      margin: 68px 0 0 0; }
      #PageProject.pageEntry #Main .linkBox ul {
        justify-content: center; }
        #PageProject.pageEntry #Main .linkBox ul li {
          margin-bottom: 30px; }

  /*##### Achievement #####*/
  #PageAchievement.pageIndex #Main .innerBasic {
    margin-left: 0;
    margin-right: 0; }
  #PageAchievement.pageIndex #Main .linkBox {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px; }
  #PageAchievement.pageIndex #Main .contBox > h3 {
    margin-bottom: 30px;
    font-size: 42px; }
  #PageAchievement.pageIndex #Main .contBox .contSubBox {
    padding: 50px 20px; }
    #PageAchievement.pageIndex #Main .contBox .contSubBox > h4 {
      margin-bottom: 30px;
      font-size: 26px; }
      #PageAchievement.pageIndex #Main .contBox .contSubBox > h4 span::before, #PageAchievement.pageIndex #Main .contBox .contSubBox > h4 span::after {
        content: none; }
    #PageAchievement.pageIndex #Main .contBox .contSubBox > ol li {
      margin-bottom: 20px; }
      #PageAchievement.pageIndex #Main .contBox .contSubBox > ol li div p {
        line-height: 1.75;
        text-align: left; }

  /*##### Member #####*/
  #PageMember.pageIndex #Main {
    margin-bottom: 135px; }
    #PageMember.pageIndex #Main .innerBasic {
      margin-left: 0;
      margin-right: 0; }
    #PageMember.pageIndex #Main .contSubBox {
      margin-bottom: 55px;
      padding-bottom: 10px; }
      #PageMember.pageIndex #Main .contSubBox h3 {
        font-size: 44px;
        margin-bottom: 40px; }
      #PageMember.pageIndex #Main .contSubBox .memberList {
        margin-left: 20px;
        margin-right: 20px; }
        #PageMember.pageIndex #Main .contSubBox .memberList dl {
          position: relative;
          margin-bottom: 45px; }
          #PageMember.pageIndex #Main .contSubBox .memberList dl dt {
            margin-left: 138px;
            margin-bottom: 3px;
            line-height: 1.3; }
            #PageMember.pageIndex #Main .contSubBox .memberList dl dt span {
              display: block;
              letter-spacing: 0.01em; }
          #PageMember.pageIndex #Main .contSubBox .memberList dl dd {
            margin-left: 138px; }
            #PageMember.pageIndex #Main .contSubBox .memberList dl dd ul {
              margin-top: 3px; }
              #PageMember.pageIndex #Main .contSubBox .memberList dl dd ul li {
                line-height: 1.25; }
            #PageMember.pageIndex #Main .contSubBox .memberList dl dd:first-of-type {
              letter-spacing: 0.01em; }
            #PageMember.pageIndex #Main .contSubBox .memberList dl dd.cmt {
              margin-top: 10px;
              margin-left: 0;
              line-height: 1.7; }
            #PageMember.pageIndex #Main .contSubBox .memberList dl dd.photo {
              position: absolute;
              top: 0;
              left: 0;
              margin-left: 0; }
              #PageMember.pageIndex #Main .contSubBox .memberList dl dd.photo img {
                width: 120px;
                height: 120px; }
        #PageMember.pageIndex #Main .contSubBox .memberList.column4 {
          margin-bottom: 50px; }
          #PageMember.pageIndex #Main .contSubBox .memberList.column4 dl {
            margin-bottom: 20px;
            padding: 0; }
            #PageMember.pageIndex #Main .contSubBox .memberList.column4 dl dt, #PageMember.pageIndex #Main .contSubBox .memberList.column4 dl dd {
              margin-left: 0; }
            #PageMember.pageIndex #Main .contSubBox .memberList.column4 dl dt span {
              display: inline; }
        #PageMember.pageIndex #Main .contSubBox .memberList.noPhoto dl {
          margin-bottom: 20px; }
          #PageMember.pageIndex #Main .contSubBox .memberList.noPhoto dl dt, #PageMember.pageIndex #Main .contSubBox .memberList.noPhoto dl dd {
            margin-left: 0; }
    #PageMember.pageIndex #Main .subBox02 .memberList dl {
      padding-top: 25px; }
      #PageMember.pageIndex #Main .subBox02 .memberList dl.type01 dd.cmt {
        margin-top: 30px; }
    #PageMember.pageIndex #Main .subBox03 h3 {
      margin-bottom: 30px; }
    #PageMember.pageIndex #Main .subBox03 h4 {
      margin-bottom: 30px; }
    #PageMember.pageIndex #Main .subBox03 .memberList dl {
      padding-top: 25px; }
      #PageMember.pageIndex #Main .subBox03 .memberList dl.type01 dd.cmt {
        margin-top: 30px; }
    #PageMember.pageIndex #Main .subBox05 .memberList.noPhoto dl {
      margin-bottom: 26px; }
      #PageMember.pageIndex #Main .subBox05 .memberList.noPhoto dl dt span:first-of-type {
        margin-bottom: 6px;
        margin-left: -0.6em; }

  /*##### Join #####*/
  #PageJoin.pageIndex .linkBox {
    margin-bottom: 60px; }
    #PageJoin.pageIndex .linkBox ul {
      display: block; }
      #PageJoin.pageIndex .linkBox ul li {
        width: 100%;
        margin-right: 0;
        margin-left: 0; }
        #PageJoin.pageIndex .linkBox ul li a {
          width: 100%; }
        #PageJoin.pageIndex .linkBox ul li:nth-of-type(2) a {
          padding-top: 8px;
          height: 60px;
          border-radius: 30px;
          line-height: 1.3; }
  #PageJoin.pageIndex #Main {
    margin-bottom: 125px; }
    #PageJoin.pageIndex #Main .contBox {
      margin-bottom: 45px; }
      #PageJoin.pageIndex #Main .contBox h3 {
        margin-bottom: 35px;
        padding-top: 51px;
        font-size: 36px;
        line-height: 1.25; }
        #PageJoin.pageIndex #Main .contBox h3::after {
          font-size: 80px; }
    #PageJoin.pageIndex #Main #Student > .innerBasic {
      margin-bottom: 50px; }
    #PageJoin.pageIndex #Main #Student .contSubBox {
      margin-bottom: 60px; }
      #PageJoin.pageIndex #Main #Student .contSubBox figure {
        margin-bottom: 30px; }
        #PageJoin.pageIndex #Main #Student .contSubBox figure img {
          width: 100%; }
      #PageJoin.pageIndex #Main #Student .contSubBox h4 {
        margin-bottom: 30px; }
        #PageJoin.pageIndex #Main #Student .contSubBox h4 span {
          padding-right: 12px;
          white-space: nowrap; }
      #PageJoin.pageIndex #Main #Student .contSubBox .boxBtn {
        margin-top: 40px;
        text-align: left; }
        #PageJoin.pageIndex #Main #Student .contSubBox .boxBtn a, #PageJoin.pageIndex #Main #Student .contSubBox .boxBtn span {
          padding-left: 32px; }
          #PageJoin.pageIndex #Main #Student .contSubBox .boxBtn a::after, #PageJoin.pageIndex #Main #Student .contSubBox .boxBtn span::after {
            top: 16px;
            left: 85px; }
      #PageJoin.pageIndex #Main #Student .contSubBox:last-of-type {
        margin-bottom: 0;
        padding-bottom: 30px; }
    #PageJoin.pageIndex #Main #Pharmacist .innerBasic {
      margin-left: 0;
      margin-right: 0;
      padding-left: 20px;
      padding-right: 20px; }
    #PageJoin.pageIndex #Main #Pharmacist ul {
      margin: 35px 0; }
      #PageJoin.pageIndex #Main #Pharmacist ul li {
        margin-bottom: 15px;
        padding: 20px 15px 23px;
        line-height: 1.7; }

  /*##### Contact #####*/
  #PageContact #Main {
    margin-bottom: 100px; }
    #PageContact #Main .linkBox {
      margin-bottom: 30px; }
    #PageContact #Main #ContactForm .innerBasic {
      margin-left: 0;
      margin-right: 0; }
    #PageContact #Main #ContactForm h3 {
      margin-bottom: 20px; }
    #PageContact #Main #ContactForm p {
      margin-left: 20px;
      margin-right: 20px; }
    #PageContact #Main #ContactForm .contSubBox {
      margin: 45px 0;
      padding: 50px 20px 65px; }
    #PageContact #Main #Access h3 {
      margin-bottom: 25px; }

  #PageContact.pageIndex #Main #ContactForm table tr:nth-of-type(4) td {
    margin-bottom: 35px;
    padding-top: 10px; }
    #PageContact.pageIndex #Main #ContactForm table tr:nth-of-type(4) td label:last-of-type {
      margin-bottom: 0; }

  #PageContact.pageConfirm #Main #ContactForm .boxBtn {
    margin-bottom: 25px; }
  #PageContact.pageConfirm #Main #ContactForm input.reset {
    color: #205edb; }

  /*##### English #####*/
  #PageEn.pageIndex #MainImg #MainImgInner {
    height: 600px;
    background-image: url(../img/contents/top/mainimg_sp.jpg); }
  #PageEn.pageIndex #Main {
    margin-bottom: 115px; }
    #PageEn.pageIndex #Main h4 {
      font-size: 32px;
      line-height: 1.22;
      letter-spacing: 0.02em; }
    #PageEn.pageIndex #Main p {
      margin-bottom: 30px;
      text-align: left; }
    #PageEn.pageIndex #Main figure img {
      width: 100%; }
    #PageEn.pageIndex #Main #About {
      margin-bottom: 50px; }
      #PageEn.pageIndex #Main #About .innerBasic {
        margin-left: 0;
        margin-right: 0; }
      #PageEn.pageIndex #Main #About h3 {
        margin-bottom: 10px; }
      #PageEn.pageIndex #Main #About h4 {
        text-align: center; }
      #PageEn.pageIndex #Main #About .contSubBox {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 55px; }
      #PageEn.pageIndex #Main #About figure {
        margin: 33px 0; }
    #PageEn.pageIndex #Main #Research h4 {
      margin-bottom: 27px; }
    #PageEn.pageIndex #Main #Research .contSubBox ul.listBox {
      padding: 35px 20px 20px; }
      #PageEn.pageIndex #Main #Research .contSubBox ul.listBox li {
        margin-left: 20px;
        margin-bottom: 14px;
        line-height: 1.7; }
        #PageEn.pageIndex #Main #Research .contSubBox ul.listBox li::before {
          top: 13px;
          left: -20px; }
    #PageEn.pageIndex #Main #Research .contSubBox:nth-of-type(1) {
      margin-bottom: 50px; }
      #PageEn.pageIndex #Main #Research .contSubBox:nth-of-type(1) figure {
        margin: 33px 0; }
    #PageEn.pageIndex #Main #Research .contSubBox:nth-of-type(2) {
      margin-bottom: 30px; }
      #PageEn.pageIndex #Main #Research .contSubBox:nth-of-type(2) figure {
        margin: 33px 0; } }
@media print, screen and (min-width: 380px) and (max-width: 767px) {
  #PageMember.pageIndex #Main .contSubBox .memberList dl dd.cmt {
    margin-top: 30px; } }
@media print, screen and (max-width: 335px) {
  #Page.pageIndex #Main #ContBox03 .contSubBox img,
  #PageProject.pageIndex #Main .box02 img {
    width: 285px; }

  #MainImg #MainImgInner h2 {
    font-size: 50px; }

  #PageAchievement.pageIndex #Main .contBox .contSubBox > ol li div p {
    word-break: break-all; }

  #PageJoin.pageIndex #Main #Pharmacist h3::after {
    letter-spacing: -0.02em;
    font-size: 70px; }

  #PageJoin.pageIndex #Main #Student .contSubBox h4 span {
    white-space: normal; }

  #PageJoin.pageIndex #Main .contBox .contSubBox h4::before {
    content: none; } }
